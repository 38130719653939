import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../api';

interface InitialStatus {
  loading: boolean;
  errorMsg: string;
  isSuccessful: boolean;
}

const initialStatus: InitialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};

interface Filter {
  fromEmail?: string;
  domains?: string[];
  toEmail?: string;
  startDate?: string; 
  endDate?: string; 
  status?: any;
  page?: number;
  limit?: number;
  sort_by?: string;
  sort_order?: string;
}

interface EmailState {
    emailInsights: any;
    fetchEmailInsights: (filter: Filter) => Promise<any>;
}

const useEmailStore = create<EmailState>()(
  devtools((set) => ({
    emailInsights: initialStatus,
    fetchEmailInsights: async (filter: Filter) => {
      try {
        set(() => ({ emailInsights: { ...initialStatus } }));
        const response = await api.Email.getEmailInsights(filter);
        const resData = { ...response, loading: false, isSuccessful: true };
        set(() => ({ emailInsights: resData }));
      } catch (error) {
        set(() => ({
            emailInsights: {
            ...initialStatus,
            loading: false,
            isSuccessful: false,
            errorMsg: (error as Error).message,
          },
        }));
        throw error;
      }
    },
   
  }))
);

export default useEmailStore;
