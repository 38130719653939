import GurulyticsTable from "../../components/GurulyticsTable";
import ClientSearchBar from "../../components/ClinetsSearchBar";
import ClientSearch from "../../components/PerformanceInsights/ClientSearchBar";
import ReactSelect from "../../components/ReactSelect";
import DatePicker from 'react-datepicker';
import moment from "moment";
import useEmailStore from "../../store/useEmailStore";
import { getStatusBadgeColor } from "../../utils/common";
import { useEffect, useState } from "react";

const EmailInsights = () => {
    const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
    const [selectedClient, setSelectedClient] = useState<any | null>(null);
    const {fetchEmailInsights, emailInsights} = useEmailStore()
    const [emailService, setEmailService] = useState<{
        label: string;
        value: string;
      }>({
        label: "All Service", 
        value: "", 
      });
      const [status, setStatus] = useState<{
        label: string;
        value: string;
      }>({
        label: "All Status", 
        value: "", 
      });
    const [dateRange, setDateRange] = useState([null, null]);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [startDate, endDate] = dateRange;
    const [emailTo,setEmailTo] = useState('')

    const handleFetchEmailInsights = () => {
      const filter = {
        domains: selectedClient?.domain ? [selectedClient.domain] : null, 
        fromEmail: emailService?.value,
        page: page,
        limit: limit,
        toEmail: emailTo,
        startDate: startDate ?  moment(startDate).format('YYYY-MM-DD') : null,
        endDate:  endDate ?  moment(endDate).format('YYYY-MM-DD'): null,
        status: status?.value
      }
      fetchEmailInsights(filter)
    }

    console.log(startDate)

    useEffect(()=> {
        handleFetchEmailInsights()
    },[page, limit, status, selectedClient, emailService, endDate])    

    const columns = [
        {
            id: 'Subject',
            header: () => (
              <div className="flex justify-start w-[180px] sm:w-[20vw] text-sm font-[600] font-inter">
                Subject
              </div>
            ),
            accessorKey: 'subject',
            cell: (props: any) => (
              <div className="text-sm h-[30px] flex justify-start items-center">
                {props.getValue()}
              </div>
            ),
        },
        {
            id: 'Email',
            header: () => (
              <div className="flex justify-start w-[180px] sm:w-[8vw] text-sm font-[600] font-inter">
                Email
              </div>
            ),
            accessorKey: 'to_email',
            cell: (props: any) => (
                <div className="flex justify-start text-sm">
                    {props.getValue()}
                </div>
            ),
        },
        {
            id: 'Status',
            header: () => (
              <div className="flex justify-center w-[180px] sm:w-[8vw] text-sm font-[600] font-inter">
                Status
              </div>
            ),
            accessorKey: 'status',
            cell: (props: any) => (
              <div className="uppercase flex justify-center text-sm font-[600]">
                <div className={`text-xs ${getStatusBadgeColor(props?.getValue())?.color} p-1 px-2 rounded-full flex gap-2 items-center justify-center cursor-pointer dark:text-white`}>
                   {getStatusBadgeColor(props?.getValue()).text}
                </div>
              </div>
            ),
        },
        {
            id: 'Open Counts',
            header: () => (
              <div className="flex justify-center w-[180px] sm:w-[6vw] text-sm font-[600] font-inter">
                Open Count
              </div>
            ),
            accessorKey: 'opens_count',
            cell: (props: any) => (
                <div className="flex justify-center text-sm">
                    {props.getValue()}
                </div>
            ),
        },
        {
            id: 'Click Counts',
            header: () => (
              <div className="flex justify-center w-[180px] sm:w-[6vw] text-sm font-[600] font-inter">
                Click Count
              </div>
            ),
            accessorKey: 'clicks_count',
            cell: (props: any) => (
                <div className="flex justify-center text-sm">
                    {props.getValue()}
                </div>
            ),
        },
        // {
        //     id: 'CreatedAt',
        //     header: () => (
        //       <div className="flex justify-center w-[180px] sm:w-[10vw] text-sm font-[600] font-inter">
        //         Created At
        //       </div>
        //     ),
        //     accessorKey: 'createdAt',
        //     cell: (props: any) => (
        //         <div className="flex justify-center text-sm">
        //             {moment(props.getValue()).format('MMM DD, YYYY [at] h:mm A')}
        //         </div>
        //     ),
        // },
        {
            id: 'LastEventTime',
            header: () => (
                <div className="flex justify-center w-[180px] sm:w-[12vw] text-sm font-[600] font-inter">
                    Last Event Time
                </div>
            ),
            accessorKey: 'last_event_time',
            cell: (props: any) => (
                <div className="flex justify-center text-sm">
                    {moment(props.getValue()).format('MMM DD, YYYY [at] h:mm A')}
                </div>
            ),
        },
    ]

  return (
    <div className="px-6 py-2">
        <div className="flex gap-2 items-center bg-[#FFF] p-4 rounded-tl-md rounded-tr-md">
            <div className="w-[250px]">
                <ClientSearch
                    setSelectedClientId={setSelectedClientId}
                    selectedClientId={selectedClientId}
                    setSelectedClient={setSelectedClient}
                />
            </div>
            <div className="w-[250px]">
              <input
                type="search"
                value={emailTo} onChange={(e) => {
                  setEmailTo(e.target.value)
                  const newValue = e.target.value;
                  if (newValue.trim() === "") {
                    setEmailTo('')
                    handleFetchEmailInsights(); // Trigger refetch logic
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleFetchEmailInsights();
                  }
                }}
                className="block w-full p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search by Client's email"
              />
            </div>
            <div>
                <ReactSelect
                value={emailService}
                options={[
                    { value: '', label: 'All Reports' },
                    { label: 'Google SEO Monthly', value: 'seo.google.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Google SEO Monthly Cycle 1', value: 'seo.google.monthly.report.cycle_1@onlinemarketinggurus.com.au' },
                    { label: 'Google SEO Monthly Cycle 2', value: 'seo.google.monthly.report.cycle_2@onlinemarketinggurus.com.au' },
                    { label: 'Meta Monthly', value: 'social.meta.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Bing SEO Monthly', value: 'seo.bing.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Google Ads Weekly', value: 'ppc.google.weekly.report@onlinemarketinggurus.com.au' },
                    { label: 'Google Ads Monthly', value: 'ppc.google.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Bing Ads Monthly', value: 'ppc.bing.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Tiktok Monthly', value: 'social.tiktok.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'LinkedIn Monthly', value: 'social.linkedin.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Pinterest Monthly', value: 'social.pinterest.monthly.report@onlinemarketinggurus.com.au' },
                    { label: 'Display Monthly', value: 'display.monthly.report@onlinemarketinggurus.com.au' },
                ]}
                handleOnChange={(selectedOption: any) => {
                    setEmailService(selectedOption)
                }}
                placeholder="All Reports"
                />
            </div>
            <div>
                <ReactSelect
                value={status}
                options={[
                    { value: '', label: 'All Status' },
                    { value: 'delivered', label: 'Delivered' },
                    { value: 'not_delivered', label: 'Not Delivered' },
                    { value: 'processing', label: 'Processing' },
                ]}
                handleOnChange={(selectedOption: any) => {
                    setStatus(selectedOption)
                }}
                placeholder="All Status"
                />
            </div>
            <div className="z-50">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Choose date"
                className=" text-center  h-[38px] min-w-[220px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none font-inter rounded-lg text-[14px] text-[#525252]"
                />
            </div>
        </div>
        <GurulyticsTable
            data={emailInsights?.docs || [] }
            loading={emailInsights?.loading}
            columns={columns}                                        
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            totalPages={emailInsights?.totalPages}
            totalDocs={emailInsights?.totalDocs}
        />
    </div>
  );
};

export default EmailInsights;
