import { requests } from './ApiService';

export const Email = {
  getEmailInsights: (filters) =>
    requests.get(
      `/v1/sendgrid/activity${toQueryString({
        fromEmail: filters.fromEmail,
        domains: filters.domains,
        toEmail: filters.toEmail,
        startDate: filters.startDate,
        endDate: filters.endDate,
        status: filters.status,
        page: filters.page,
        limit: filters.limit,
        sort_by: filters.sortBy,
        sort_order: filters.sortOrder,
      })}`,
      null,
      'https://staging-mailer.gurulytics.app' 
    ),
};

// performance unique to format users and clients
const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        params[key] !== 'all' &&
        params[key] !== '' 

    )
    .map((key, index) => {
      if (
        key === 'domains' ||
        key === 'users' ||
        key === 'clientIds'
        // ||
        // key === 'clientPerformanceIds'
      ) {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(
              (value) =>
                `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(value)}`
            )
            .join('&');
        }
        return `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(params[key])}`;
      }
      return `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(params[key])}`;
    })
    .join('');
