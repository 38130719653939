import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../api';

interface Filter {
  searchTerm?: string;
  contactId?: string;
  page?: number;
  pageSize?: number;
  status?: string[];
  // Add more filters here
}

interface XeroState {
  contacts: any;
  invoices: any;
  invoicesLink: any;
  loading: boolean;
  fetchContacts: (filter: Filter) => Promise<any>;
  fetchInvoices: (filter: Filter) => Promise<any>;
  fetchInvoicesPDF: (clientId: string, invoiceId?: string) => Promise<any>;
  resetInvoiceLink: () => void;
}

const useXeroStore = create<XeroState>()(
  devtools((set) => ({
    contacts: [],
    invoices: [],
    invoicesLink: '',
    loading: true,
    fetchContacts: async (filter: Filter) => {
      try {
        set(() => ({ loading: true }));
        const response = await api.Xero.getContacts(filter);
        set(() => ({ contacts: response, loading: false }));
      } catch (error) {
        set(() => ({}));
        throw error; // Handle this error in the consuming component
      }
    },
    fetchInvoices: async (filters: any) => {
      try {
        set(() => ({ loading: true }));
        const response = await api.Xero.getInvoices(filters);
        set(() => ({ loading: false, invoices: response  }));
      } catch (error) {
        set(() => ({ loading: false }));
        throw error; // Handle this error in the consuming component
      }
    },
    fetchInvoicesPDF: async (clientId: string, invoiceId: string) => {
      try {
        const response = await api.Xero.getInvoicesLink(clientId, invoiceId);
        set(() => ({ invoicesLink: response }));
      } catch (error) {
        set(() => ({}));
        throw error; // Handle this error in the consuming component
      }
    },
    resetInvoiceLink: () => {
      set(() => ({ invoicesLink: null }));
    },
  }))
);

export default useXeroStore;
