import { useState, useEffect } from 'react';
import {  Grid, Col } from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import MissingIntegrationCards from '../MissingIntegration';
import { ComboChart } from '../../ComboChart';
import { formatNumber, formatToCurrency } from '../../../utils/common';

type CustomTooltip = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};



const dataFormatter = (
  number: number | string,
  hasDollar?: boolean,
  hasPercent?: boolean
) => {
  return hasDollar
    ? formatToCurrency(number)
    : formatNumber(number, hasPercent);
};

const dataLineFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();


const PerformanceInsightsPerformance = () => {
  const [insightsStatusService, setInsightsStatusService] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const {
    insightsStatus,
    userInsightsStatus,
    fetchAllStaffStatus,
  } = usePerformanceStore((state) => state);

  const handleFetchInsightstatus = () => {
    fetchAllStaffStatus()
  };

  useEffect(() => {
    handleFetchInsightstatus();
  }, [insightsStatusService]);



  const statusData = insightsStatus?.dates?.map((date, index) => {
    const { green = {}, amber = {}, red = {}, summary = {} } = insightsStatus.values[index];

    return {
      date,
      GreenTotal: summary?.total?.green || 0,
      GreenAverage: summary?.average?.green || 0,
      green: {
        seo_google: {
          'Lead Gen': green?.seo_google?.['Lead Gen'] || 0,
          Ecommerce: green?.seo_google?.['Ecommerce'] || 0,
        },
        ppc_google: {
          'Lead Gen': green?.ppc_google?.['Lead Gen'] || 0,
          Ecommerce: green?.ppc_google?.['Ecommerce'] || 0,
        },
        social_meta: {
          'Lead Gen': green?.social_meta?.['Lead Gen'] || 0,
          Ecommerce: green?.social_meta?.['Ecommerce'] || 0,
        },
      },
      AmberTotal: summary?.total?.amber || 0,
      AmberAverage: summary?.average?.amber || 0,
      amber: {
        seo_google: {
          'Lead Gen': amber?.seo_google?.['Lead Gen'] || 0,
          Ecommerce: amber?.seo_google?.['Ecommerce'] || 0,
        },
        ppc_google: {
          'Lead Gen': amber?.ppc_google?.['Lead Gen'] || 0,
          Ecommerce: amber?.ppc_google?.['Ecommerce'] || 0,
        },
        social_meta: {
          'Lead Gen': amber?.social_meta?.['Lead Gen'] || 0,
          Ecommerce: amber?.social_meta?.['Ecommerce'] || 0,
        },
      },
      red: {
        seo_google: {
          'Lead Gen': red?.seo_google?.['Lead Gen'] || 0,
          Ecommerce: red?.seo_google?.['Ecommerce'] || 0,
        },
        ppc_google: {
          'Lead Gen': red?.ppc_google?.['Lead Gen'] || 0,
          Ecommerce: red?.ppc_google?.['Ecommerce'] || 0,
        },
        social_meta: {
          'Lead Gen': red?.social_meta?.['Lead Gen'] || 0,
          Ecommerce: red?.social_meta?.['Ecommerce'] || 0,
        },
      },
      RedTotal:  summary?.total?.red || 0,
      RedAverage: summary?.average?.red || 0,
      service: insightsStatusService?.label || "All Service",
      serviceKey: insightsStatusService?.value || " "
    };
  });

 

  const statusCustomTooltip = (props: CustomTooltip) => {
    const { payload, active, label,} = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const serviceKey = payloadItem?.payload?.serviceKey
    const amberData = payloadItem.payload.amber;
    const greenData = payloadItem.payload.green;
    const redData = payloadItem.payload.red;

    return (
      <>
      {payloadItem?.payload?.service !== "All Service" ? ( 
         <div className="rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 text-tremor-default shadow-tremor-dropdown">
         <div className="flex space-x-2.5">
           <div className="w-full">
           <div className='flex justify-between items-center border-b p-1'>
                  <p className="text-[10px] text-tremor-content-emphasis dark:text-white min-w-[20px] px-1">
                    
                  </p>
                  <p className="font-bold text-[10px] text-tremor-content-emphasis dark:text-white px-1">
                    {label}
                  </p>
                </div>
             {/* <Grid
                 numItems={4}
                 numItemsSm={4}
                 numItemsMd={4}
                 numItemsLg={4}
                 className={`gap-2 mt-2`}
             >
                 <Col>
                     <p className="font-medium text-tremor-content-emphasis dark:text-white">
                     {label}
                     </p>
                 </Col>
                 <Col>
                     <p className="rounded text-[10px] dark:text-white font-bold text-center">
                     Green
                     </p>
                 </Col>
                 <Col>
                     <p className="rounded text-[10px] dark:text-white font-bold text-center">
                     Amber 
                     </p>
                 </Col>
                 <Col>
                     <p className="rounded text-[10px] dark:text-white font-bold text-center">
                     Red
                     </p>
                 </Col>
             </Grid> */}
                 <Grid
                     numItems={4}
                     numItemsSm={4}
                     numItemsMd={4}
                     numItemsLg={4}
                     className={`p-2`}
                     >
                     <Col>
                         <div className="flex items-center text-[10px]">
                         <div className="text-gray-700 rounded text-[10px] px-1 dark:text-white font-bold">
                             {payloadItem?.payload?.service}
                         </div>
                         </div>
                         <div className="flex items-center">
                         <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                             Lead Gen
                         </div>
                         </div>
                         <div className="flex items-center">
                         <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                             Ecommerce
                         </div>
                         </div>
                         <div className="flex items-center text-[10px]  bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px] my-[2px]">
                         <div className="text-gray-700 rounded text-[10px] px-1 dark:text-white font-bold">
                            Total
                         </div>
                         </div>
                         <div className="flex items-center text-[10px] bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px]">
                         <div className="text-gray-700 rounded text-[10px] px-1 dark:text-white font-bold">
                             Company Ave.
                         </div>
                         </div>
                     </Col>
                     <Col className="flex flex-col items-center ">
                         <div className="flex items-center ">
                         <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">
                 
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                             {payloadItem?.payload?.green?.[serviceKey]?.['Lead Gen'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                         <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                             {payloadItem?.payload?.green?.[serviceKey]?.['Ecommerce'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px]">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                             {(payloadItem?.payload?.green?.[serviceKey]?.['Lead Gen'] || 0) + (payloadItem?.payload?.green?.[serviceKey]?.['Ecommerce'] || 0)}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {formatNumber(payloadItem?.payload?.GreenAverage)}%
                         </div>
                         </div>
                     </Col>
                     <Col className="flex flex-col items-center ">
                     <div className="flex items-center ">
                     <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">
                 
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                         <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {payloadItem?.payload?.amber?.[serviceKey]?.['Lead Gen'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                         <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {payloadItem?.payload?.amber?.[serviceKey]?.['Ecommerce'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px]">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                         {(payloadItem?.payload?.amber?.[serviceKey]?.['Lead Gen'] || 0) + (payloadItem?.payload?.amber?.[serviceKey]?.['Ecommerce'] || 0)}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {formatNumber(payloadItem?.payload?.AmberAverage)}%
                         </div>
                         </div>
                     </Col>
                     <Col className="flex flex-col items-center ">
                     <div className="flex items-center text-[10px]">
                         <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">
                         
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                             <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                             {payloadItem?.payload?.red?.[serviceKey]?.['Lead Gen'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {payloadItem?.payload?.red?.[serviceKey]?.['Ecommerce'] || 0}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px] rounded-tr-[4px] rounded-br-[4px]">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                         {(payloadItem?.payload?.red?.[serviceKey]?.['Lead Gen'] || 0) + (payloadItem?.payload?.red?.[serviceKey]?.['Ecommerce'] || 0)}
                         </div>
                         </div>
                         <div className="flex items-center justify-center bg-[#e6f0ff] w-full rounded-tr-[4px] rounded-br-[4px]">
                         <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                         {formatNumber(payloadItem?.payload?.RedAverage)}%
                         </div>
                         </div>
                     </Col>
                 </Grid>
           </div>
         </div>
       </div>
       ) : (
        <div className="w-[400px] absolute top-[0.5rem] right-[-65rem] z-50 rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 text-tremor-default shadow-tremor-dropdown">
          <div className="flex space-x-2.5">
            <div className="w-full">
              {/* <Grid
                  numItems={4}
                  numItemsSm={4}
                  numItemsMd={4}
                  numItemsLg={4}
                  className={`gap-2 mt-2`}
              >
                  <Col>
                      <p className="font-medium text-tremor-content-emphasis dark:text-white">
                      {label}
                      </p>
                  </Col>
                  <Col>
                      <p className="rounded text-[10px] dark:text-white font-bold text-center">
                      Green
                      </p>
                  </Col>
                  <Col>
                      <p className="rounded text-[10px] dark:text-white font-bold text-center">
                      Amber 
                      </p>
                  </Col>
                  <Col>
                      <p className="rounded text-[10px] dark:text-white font-bold text-center">
                      Red
                      </p>
                  </Col>
              </Grid> */}
                <div className='flex justify-between items-center border-b p-1'>
                  <p className="text-[10px] text-tremor-content-emphasis dark:text-white min-w-[20px] px-1">
                    
                  </p>
                  <p className="font-bold text-[10px] text-tremor-content-emphasis dark:text-white px-1">
                    {label}
                  </p>
                </div>
                  <Grid
                  numItems={4}
                  numItemsSm={4}
                  numItemsMd={4}
                  numItemsLg={4}
                  className={`p-2`}
                  >
                  <Col>
                      <div className="flex items-center text-[10px]">
                      <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                          Google SEO
                      </div>
                      </div>
                      <div className="flex items-center">
                        
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                      </div>
                      </div>
                      <div className="flex items-center">
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Ecommerce
                      </div>
                      </div>
                      <div className="flex items-center text-[10px]">
                      <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                          Google Ads
                      </div>
                      </div>
                      <div className="flex items-center">
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                      </div>
                      </div>
                      <div className="flex items-center">
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Ecommerce
                      </div>
                      </div>
                      <div className="flex items-center text-[12px]">
                      <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                          Meta
                      </div>
                      </div>
                      <div className="flex items-center">
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                      </div>
                      </div>
                      <div className="flex items-center">
                      <div className="rounded text-[10px] px-1 dark:text-white font-[500]">
                          Ecommerce
                      </div>
                      </div>
                      <div className="flex items-center text-[10px]  bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px] my-[2px]">
                      <div className="text-gray-700 rounded text-[10px] px-1 dark:text-white font-bold">
                           Total
                      </div>
                      </div>
                      <div className="flex items-center text-[10px] bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px]">
                      <div className="text-gray-700 rounded text-[10px] px-1 dark:text-white font-bold">
                          Company Ave.
                      </div>
                      </div>
                  </Col>
                  <Col className="flex flex-col items-center ">
                  <div className="flex items-center ">
                  <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">

                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                          {greenData?.seo_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                          {greenData?.seo_google?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {greenData?.ppc_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {greenData?.ppc_google?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                          
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {greenData?.social_meta?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#22C55E]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {greenData?.social_meta?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px]">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                          {payloadItem?.payload?.GreenTotal}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.GreenAverage)}%
                      </div>
                      </div>
                  </Col>
                  <Col className="flex flex-col items-center ">
                  <div className="flex items-center ">
                  <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">
              
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.seo_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.seo_google?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.ppc_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.ppc_google?.['Ecommerce']}  
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.social_meta?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#FFB545]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {amberData?.social_meta?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px]">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                      {payloadItem?.payload?.AmberTotal}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.AmberAverage)}%
                      </div>
                      </div>
                  </Col>
                  <Col className="flex flex-col items-center ">
                  <div className="flex items-center text-[10px]">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {redData?.seo_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {redData?.seo_google?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {redData?.ppc_google?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {redData?.ppc_google?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                          {redData?.social_meta?.['Lead Gen']}
                      </div>
                      </div>
                      <div className="flex items-center gap-1">
                      <div className='w-2 h-2 rounded-full bg-[#EF4444]'></div>
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {redData?.social_meta?.['Ecommerce']}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full my-[2px] rounded-tr-[4px] rounded-br-[4px]">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-bold text-[#3CCB7F]">
                      {payloadItem?.payload?.AmberTotal}
                      </div>
                      </div>
                      <div className="flex items-center justify-center bg-[#e6f0ff] w-full rounded-tr-[4px] rounded-br-[4px]">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.RedAverage)}%
                      </div>
                      </div>
                  </Col>
                  </Grid>
            
            </div>
          </div>
        </div>
       )}
      </>
     
    );
  };

  return (
    <>
      <div className="border-t pt-4">
        <div className="font-[700] text-[18px] text-[#001C44] mb-4 dark:text-gray-300">
          Client Performance
        </div>
        <Grid
          className="gap-4 mb-4"
          numItems={1}
          numItemsSm={1}
          numItemsMd={3}
          numItemsLg={3}
        >
        <Col numColSpan={2} />

          <Col numColSpanLg={1}>
            <ReactSelect
              value={insightsStatusService}
              options={[
                { value: '', label: 'All Service' },
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setInsightsStatusService(selectedOption);
              }}
              placeholder="All Service"
            />
          </Col>
        </Grid>
        {/* <MissingIntegrationCards selectedUserId={selectedUserId} /> */}
        {insightsStatus.loading ? (
          <div className="w-full h-[242px] flex flex-col gap-5 justify-center items-center">
            <div className="w-1/2 bg-gray-300 rounded-full overflow-hidden">
              <div className="h-2 bg-blue-500 rounded-full animate-loading-bar" />
            </div>
            <div className="font-bold animate-pulse dark:text-white">
              Loading Data
            </div>
          </div>
        ) : (
          <ComboChart
            data={statusData}
            index="date"
            showLegend={false}
               barSeries={{
              categories:  ['GreenAverage', 'AmberAverage', 'RedAverage'],
              colors: ['green', 'amber', 'rose','seo', 'ppc', 'meta'],
              yAxisWidth: 40,
           
              // valueFormatter: (value) => dataFormatter(value, false, true),
            }}
            // lineSeries={{
            //   categories: ['GreenAverage', 'AmberAverage', 'RedAverage'],
            //   yAxisWidth: 40,

            //   // valueFormatter: (value) => dataFormatter(value, false, true),
            // }}
            customTooltip={ statusCustomTooltip}
          />
         
        )}
      </div>
    </>
  );
};

export default PerformanceInsightsPerformance;