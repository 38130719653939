import { useState, useEffect } from 'react';
import { Card, Grid, Col, LineChart } from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import UserSearch from '../../PerformanceInsights/UserSearchbar';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import MissingIntegrationCards from '../MissingIntegration';
import { ComboChart } from '../../ComboChart';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import moment from 'moment';
import { CgLayoutGrid } from 'react-icons/cg';

type CustomTooltip = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

const dataFormatter = (
  number: number | string,
  hasDollar?: boolean,
  hasPercent?: boolean
) => {
  return hasDollar
    ? formatToCurrency(number)
    : formatNumber(number, hasPercent);
};

const dataLineFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

const today = new Date();
const previousMonth = new Date(today.setMonth(today.getMonth() - 1));
const month = previousMonth.toLocaleString('default', { month: 'long' });

const PerformanceInsightsAM = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<string | null>(null);
  const [selectedUserName, setSelectedUserName] = useState<string | null>(null);
  const [duration, setDuration] =useState<{
    label: string;
    value: number;
  }>({
    label: "6 Months", 
    value: 6, 
  });
  const [insightsStatusService, setInsightsStatusService] = useState<{
    label: string;
    value: string;
  }>({
    label: "All Service", 
    value: "all", 
  });
  
  const {
    userInsightsStatusAM,
    fetchAMUserStaffStatus,
  } = usePerformanceStore((state) => state);



  const handleFetchStaffStatus = () => {
    if(selectedUserId || insightsStatusService?.value ) {
        const filters = {
            userId: selectedUserId,
            service: insightsStatusService?.value
        }
        fetchAMUserStaffStatus(filters)
      }
  };

  useEffect(() => {
    handleFetchStaffStatus();
  }, [selectedUserId,insightsStatusService]);

  const userInsightsStatusArray = userInsightsStatusAM?.dates?.map((date, index) => {
    const { seo_google = {}, ppc_google = {}, social_meta = {}, company_average = {}, average = {} } = userInsightsStatusAM.values[index];
    return {
      date,
      companyAverageTotalSEO: (company_average?.seo_google?.['Lead Gen']?.average + company_average?.seo_google?.['Ecommerce']?.average)/2, 
      companyCountTotalSEO: (company_average?.seo_google?.['Lead Gen']?.count + company_average?.seo_google?.['Ecommerce']?.count), 
      companyAverageTotalPPC: (company_average?.ppc_google?.['Lead Gen']?.average + company_average?.ppc_google?.['Ecommerce']?.average)/2, 
      companyCountTotalPPC: (company_average?.ppc_google?.['Lead Gen']?.count + company_average?.ppc_google?.['Ecommerce']?.count), 
      companyAverageTotalMeta: (company_average?.social_meta?.['Lead Gen']?.average + company_average?.social_meta?.['Ecommerce']?.average)/2,
      companyCountTotalMeta: (company_average?.social_meta?.['Lead Gen']?.count + company_average?.social_meta?.['Ecommerce']?.count), 
      leadGenSEO: seo_google?.['Lead Gen']?.average,
      ecommerceSEO: seo_google?.['Ecommerce']?.average,
      leadGenPPC: ppc_google?.['Lead Gen']?.average,
      ecommercePPC: ppc_google?.['Ecommerce']?.average,
      leadGenMeta: social_meta?.['Lead Gen']?.average,
      ecommerceMeta: social_meta?.['Ecommerce']?.average,
      leadGenSEOCount: seo_google?.['Lead Gen']?.count,
      ecommerceSEOCount: seo_google?.['Ecommerce']?.count,
      leadGenPPCCount: ppc_google?.['Lead Gen']?.count,
      ecommercePPCCount: ppc_google?.['Ecommerce']?.count,
      leadGenMetaCount: social_meta?.['Lead Gen']?.count,
      ecommerceMetaCount: social_meta?.['Ecommerce']?.count,
      avgPerfomanceServiceSEO: (seo_google?.['Lead Gen']?.average + seo_google?.['Ecommerce']?.average)/2,
      avgPerfomanceServicePPC: (ppc_google?.['Lead Gen']?.average + ppc_google?.['Ecommerce']?.average)/2,
      avgPerfomanceServiceMeta: (social_meta?.['Lead Gen']?.average + social_meta?.['Ecommerce']?.average)/2,
      totalAvg: (((seo_google?.['Lead Gen']?.average + seo_google?.['Ecommerce']?.average)/2) + ((ppc_google?.['Lead Gen']?.average + ppc_google?.['Ecommerce']?.average)/2) + ((social_meta?.['Lead Gen']?.average + social_meta?.['Ecommerce']?.average)/2)/3),
      companyAvgTotal: (((company_average?.seo_google?.['Lead Gen']?.average + company_average?.seo_google?.['Ecommerce']?.average)/2) + ((company_average?.ppc_google?.['Lead Gen']?.average + company_average?.ppc_google?.['Ecommerce']?.average)/2) + ((company_average?.social_meta?.['Lead Gen']?.average + company_average?.social_meta?.['Ecommerce']?.average)/2))/3,
      companyCountTotal: ((company_average?.seo_google?.['Lead Gen']?.count + company_average?.seo_google?.['Ecommerce']?.count) + (company_average?.ppc_google?.['Lead Gen']?.count + company_average?.ppc_google?.['Ecommerce']?.count) + (company_average?.social_meta?.['Lead Gen']?.count + company_average?.social_meta?.['Ecommerce']?.count)),
      countTotal: ((seo_google?.['Lead Gen']?.count + seo_google?.['Ecommerce']?.count) + (ppc_google?.['Lead Gen']?.count + ppc_google?.['Ecommerce']?.count) + (social_meta?.['Lead Gen']?.count + social_meta?.['Ecommerce']?.count)),
      company_average: company_average,
      average: average,
      seo_google: seo_google,
      ppc_google: ppc_google,
      social_meta: social_meta,
      service: insightsStatusService?.label || "All Service",
      serviceKey: insightsStatusService?.value || "",
      selectedUserId: selectedUserId,
      selectedUserName: selectedUserName,
    };
  })?.slice(-duration?.value);

   const userCustomTooltip = (props: CustomTooltip) => {
      const { payload, active, label } = props;
      if (!active || !payload || payload.length === 0) return null;
  
      const payloadItem = payload[0];
      const serviceKey = payloadItem?.payload?.serviceKey
      return (
        <>
         {payloadItem?.payload?.service === "All Service" ? (  
              <div className="w-[275px] rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 text-tremor-default shadow-tremor-dropdown">
              <div className='flex justify-between items-center border-b p-1'>
                <p className="text-[10px] text-tremor-content-emphasis dark:text-white min-w-[20px] px-1">
                  {payloadItem?.payload?.selectedUserName ? payloadItem?.payload?.selectedUserName : 'Company Average'}
                </p>
                <p className="font-bold text-[10px] text-tremor-content-emphasis dark:text-white px-1">
                  {label}
                </p>
            </div>
            <div className="flex space-x-2.5 p-1">
              {payloadItem?.payload?.selectedUserId  ? (
                  <div className="w-full">
                  <Grid
                    numItems={3}
                    numItemsSm={3}
                    numItemsMd={3}
                    numItemsLg={3}
                >
                    <Col className='flex items-center justify-start'>
                        <p className="font-medium text-tremor-content-emphasis dark:text-white">
                        </p>
                    </Col>
                    <Col className='flex items-center justify-center'>
                        <p className="rounded text-gray-700 text-[10px] dark:text-white font-bold text-center  leading-none">
                        Average Performance 
                        </p>
                    </Col>
                    <Col className='flex items-center justify-center'>
                        <p className="rounded text-gray-700 text-[10px] dark:text-white font-bold text-center leading-none">
                        Company Average 
                        </p>
                    </Col>
                  </Grid>
                  
                  <Grid
                    numItems={3}
                    numItemsSm={3}
                    numItemsMd={3}
                    numItemsLg={3}
                    
                  >
                    <Col>
                      <div className="flex items-center text-[10px]">
                        <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                          Google SEO
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          E-Commerce
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Total
                        </div>
                      </div>
                      <div className="flex items-center text-[10px]">
                        <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                          Google Ads
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        E-Commerce
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Total
                        </div>
                      </div>
                      <div className="flex items-center text-[12px]">
                        <div className="rounded text-gray-700 text-[10px] px-1 dark:text-white font-bold">
                            Meta
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Lead Gen
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        E-Commerce
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                          Total
                        </div>
                      </div>
                      <div className="flex items-center bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px]">
                        <div className=" text-gray-700 text-[10px] px-1 dark:text-white font-bold ">
                          Total Average
                        </div>
                      </div>
                      {/* <div className="flex items-center text-[10px]">
                        <div className=" rounded text-[10px] px-1 dark:text-white font-bold">
                          Company Average Total
                        </div>
                      </div> */}
                    </Col>
                    <Col className="flex flex-col items-center w-full">
                      <div className="flex items-center">
                        <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold leading-none">
                          
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                        {formatNumber(payloadItem?.payload?.leadGenSEO)}% ({formatNumber(payloadItem?.payload?.leadGenSEOCount)})
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.ecommerceSEO)}% ({formatNumber(payloadItem?.payload?.ecommerceSEOCount)})
                      </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.avgPerfomanceServiceSEO)}% ({formatNumber(payloadItem?.payload?.leadGenSEOCount + payloadItem?.payload?.ecommerceSEOCount)})
                      </div>
                      </div>
                      
                      <div className="flex items-center ">
                      <div className="text-center rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.leadGenPPC)}% ({formatNumber(payloadItem?.payload?.leadGenPPCCount)})
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.ecommercePPC)}% ({formatNumber(payloadItem?.payload?.ecommercePPCCount)})
                        </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.avgPerfomanceServicePPC)}% ({formatNumber(payloadItem?.payload?.leadGenPPCCount + payloadItem?.payload?.ecommercePPCCount)})
                        </div>
                      </div>
  
                      <div className="flex items-center ">
                      <div className="text-center rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                        
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.leadGenMeta)}% ({formatNumber(payloadItem?.payload?.leadGenMetaCount)})
                        </div>
                      </div>
                      <div className="flex items-center border-b border-dashed w-full justify-center">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.ecommerceMeta)}% ({formatNumber(payloadItem?.payload?.ecommerceMetaCount)})
                        </div>
                      </div>
                      <div className="flex items-center ">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.avgPerfomanceServiceMeta)}% ({formatNumber(payloadItem?.payload?.leadGenMetaCount + payloadItem?.payload?.ecommerceMetaCount)})
                        </div>
                      </div>
  
                      
                      <div className="flex justify-center items-center bg-[#e6f0ff] w-full">
                        <div className={`text-center rounded text-[10px] px-1 dark:text-white font-bold ${payloadItem?.payload?.totalAvg >  payloadItem?.payload?.companyAvgTotal ? "text-[#3CCB7F]": "text-[#FF0000]"} `}>
                        {formatNumber((payloadItem?.payload?.avgPerfomanceServiceSEO + payloadItem?.payload?.avgPerfomanceServicePPC + payloadItem?.payload?.avgPerfomanceServiceMeta)/3)}% ({formatNumber(payloadItem?.payload?.countTotal)})
                        </div>
                      </div>
                      {/* <div className="flex items-center ">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAvgTotal)}% ({(formatNumber(payloadItem?.payload?.companyCountTotal))})
                      </div>
                    </div> */}
                    </Col>
                    <Col className="flex flex-col items-center w-full">
                    <div className="flex items-center">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold leading-none">
                        
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Ecommerce']?.count)})
                    </div>
                    </div>
                    <div className="flex items-center ">
                      <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.companyAverageTotalSEO)}% ({formatNumber(payloadItem?.payload?.companyCountTotalSEO)})
                      </div>
                    </div>
                    
                    <div className="flex items-center ">
                    <div className="text-center rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                    
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Ecommerce']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center ">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAverageTotalPPC)}% ({formatNumber(payloadItem?.payload?.companyCountTotalPPC)})
                      </div>
                    </div>

                    <div className="flex items-center ">
                    <div className="text-center rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full justify-center">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Ecommerce']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center ">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAverageTotalMeta)}% ({formatNumber(payloadItem?.payload?.companyCountTotalMeta)})
                      </div>
                    </div>
                    <div className="flex justify-center items-center bg-[#e6f0ff] w-full  rounded-tr-[4px] rounded-br-[4px]">
                    <div className="text-center rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAvgTotal)}% ({(formatNumber(payloadItem?.payload?.companyCountTotal))})
                      </div>
                    </div>
                  </Col>
                  </Grid> 
                </div>
              ): (
                <div className="w-full">
                 
                {/* <Grid
                  numItems={2}
                  numItemsSm={2}
                  numItemsMd={2}
                  numItemsLg={2}
              >
                  <Col className='flex justify-start items-center'>
                      <p className="font-medium text-tremor-content-emphasis dark:text-white">
             
                      </p>
                  </Col>
                  <Col className='flex justify-center items-center'>
                      <p className="flex justify-center rounded text-[10px] dark:text-white font-bold text-center leading-none w-full">
                      Company Average 
                      </p>
                  </Col>
                </Grid> */}
                
                <Grid
                  numItems={2}
                  numItemsSm={2}
                  numItemsMd={2}
                  numItemsLg={2}
                >
                  <Col>
                    <div className="flex items-center text-[10px]">
                      <div className="rounded text-[10px] px-1 dark:text-white font-bold">
                        Google SEO
                      </div>
                    </div>
                    <div className="flex items-center  border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Lead Gen
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                      E-Commerce
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Total
                      </div>
                    </div>
                    <div className="flex items-center text-[10px]">
                      <div className="rounded text-[10px] px-1 dark:text-white font-bold">
                        Google Ads
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Lead Gen
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                      E-Commerce
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Total
                      </div>
                    </div>
                    <div className="flex items-center text-[12px]">
                      <div className="rounded text-[10px] px-1 dark:text-white font-bold">
                          Meta
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Lead Gen
                      </div>
                    </div>
                    <div className="flex items-center border-b border-dashed w-full">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                      E-Commerce
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                        Total
                      </div>
                    </div>
                  
                    <div className="flex items-center text-[10px] bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px]">
                      <div className=" rounded text-[10px] px-1 dark:text-white font-bold">
                        Company Average Total
                      </div>
                    </div>
                  </Col>
                  <Col className="flex flex-col items-center w-full">
                    <div className="flex items-center">
                      <div className="text-center rounded w-[20px] h-[20px] px-1 dark:text-white font-bold leading-none">
                        
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.seo_google?.['Ecommerce']?.count)})
                    </div>
                    </div>
                    <div className="flex items-center justify-end w-full">
                      <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                      {formatNumber(payloadItem?.payload?.companyAverageTotalSEO)}% ({formatNumber(payloadItem?.payload?.companyCountTotalSEO)})
                      </div>
                    </div>
                    
                    <div className="flex items-center justify-end w-full ">
                    <div className="text-end rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                    
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.ppc_google?.['Ecommerce']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full ">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAverageTotalPPC)}% ({formatNumber(payloadItem?.payload?.companyCountTotalPPC)})
                      </div>
                    </div>

                    <div className="flex items-center justify-end w-full ">
                    <div className="text-center rounded  w-[20px] h-[20px] px-1 dark:text-white font-[500]">
                      
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Lead Gen']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Lead Gen']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full  border-b border-dashed">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Ecommerce']?.average)}% ({formatNumber(payloadItem?.payload?.company_average?.social_meta?.['Ecommerce']?.count)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full ">
                    <div className="text-end rounded text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAverageTotalMeta)}% ({formatNumber(payloadItem?.payload?.companyCountTotalMeta)})
                      </div>
                    </div>
                    <div className="flex items-center justify-end  bg-[#e6f0ff] w-full rounded-tr-[4px] rounded-br-[4px]">
                    <div className="text-end text-[10px] px-1 dark:text-white font-[500]">
                    {formatNumber(payloadItem?.payload?.companyAvgTotal)}% ({(formatNumber(payloadItem?.payload?.companyCountTotal))})
                      </div>
                    </div>
                  </Col>
                </Grid> 
              </div>
              )}
            
            </div>
          </div>
         ) : (  

         <div className="rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 text-tremor-default shadow-tremor-dropdown">
             
           <div className="flex space-x-2.5">
              <div className="w-full">
                <div className='flex justify-between items-center border-b p-1'>
                  <p className="text-[10px] text-tremor-content-emphasis dark:text-white min-w-[20px] px-1">
                    {payloadItem?.payload?.selectedUserName ? payloadItem?.payload?.selectedUserName : 'Company Average'}
                  </p>
                  <p className="font-bold text-[10px] text-tremor-content-emphasis dark:text-white px-1">
                    {label}
                  </p>
                </div>
                 <Grid
                     numItems={3}
                     numItemsSm={3}
                     numItemsMd={3}
                     numItemsLg={3}
                     className='p-1'
                   >
                   <Col numColSpan={1}>
                     <div className="flex items-center mt-[14px]">
                       <div className="rounded text-[10px] px-1 dark:text-white font-bold">
                       {payloadItem?.payload?.service}
                       </div>
                     </div>
                     <div className="flex items-center border-b border-dashed w-full">
                       <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                         Lead Gen
                       </div>
                     </div>
                     <div className="flex items-center border-b border-dashed w-full">
                       <div className=" rounded text-[10px] px-1 dark:text-white font-[500]">
                         Ecommerce
                       </div>
                     </div>
                     <div className="flex items-center  bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px] my-[2px]">
                       <div className=" rounded text-[10px] px-1 dark:text-white font-bold">
                         Your Total
                       </div>
                     </div>
                     <div className="flex items-center bg-[#e6f0ff] w-full rounded-tl-[4px] rounded-bl-[4px]">
                       <div className=" rounded text-[10px] px-1 dark:text-white font-bold">
                         Company Average
                       </div>
                     </div>
                   </Col>
                   <Col numColSpan={2}>
                     <div className="flex items-center justify-center w-full p-1 mb-4">
                       <div className="flex items-center justify-center w-[150px] text-[10px] leading-none px-1 dark:text-white font-bold w-full">
                         Average Performance
                       </div>
                     </div>
                     <div className="flex items-center justify-center w-[150px] border-b border-dashed w-full">
                       <div className="flex items-center justify-center  text-[10px] px-1 dark:text-white font-[500]">
                       {formatNumber(payloadItem?.payload?.[serviceKey]?.['Lead Gen']?.average ?? 0)}% ({formatNumber(payloadItem?.payload?.[serviceKey]?.['Lead Gen']?.count ?? 0)})
                       </div>
                     </div>
                     <div className="flex items-center justify-center w-[150px] border-b border-dashed w-full">
                       <div className="flex items-center justify-center  rounded text-[10px] px-1 dark:text-white font-[500]">
                       {formatNumber(payloadItem?.payload?.[serviceKey]?.['Ecommerce']?.average ?? 0)}% ({formatNumber(payloadItem?.payload?.[serviceKey]?.['Ecommerce']?.count ?? 0)})
                       </div>
                     </div>
                     <div className="flex items-center justify-center w-[150px] bg-[#e6f0ff] w-full rounded-tr-[4px] rounded-br-[4px] my-[2px]">
                       <div className={`flex items-center justify-center  rounded text-[10px] px-1 dark:text-white font-bold ${formatNumber(
                           ((payloadItem?.payload?.[serviceKey]?.['Ecommerce']?.average ?? 0) +
                             (payloadItem?.payload?.[serviceKey]?.['Lead Gen']?.average ?? 0)) / 2
                         ) >  formatNumber(payloadItem?.payload?.company_average?.[serviceKey]?.average ?? 0) ? "text-[#3CCB7F]": "text-[#FF0000]"} `}>
                         {formatNumber(
                           ((payloadItem?.payload?.[serviceKey]?.['Ecommerce']?.average ?? 0) +
                             (payloadItem?.payload?.[serviceKey]?.['Lead Gen']?.average ?? 0)) / 2
                         )}%  ({formatNumber(
                          ((payloadItem?.payload?.[serviceKey]?.['Ecommerce']?.count ?? 0) +
                            (payloadItem?.payload?.[serviceKey]?.['Lead Gen']?.count ?? 0)) 
                        )})
                       </div>
                     </div>
                     <div className="flex items-center justify-center w-[150px] bg-[#e6f0ff] w-full rounded-tr-[4px] rounded-br-[4px]">
                       <div className="flex items-center justify-center  text-[10px] px-1 dark:text-white font-bold">
                         {formatNumber((payloadItem?.payload?.company_average?.[serviceKey]?.['Lead Gen']?.average + payloadItem?.payload?.company_average?.[serviceKey]?.['Ecommerce']?.average)/2)}%
                       </div>
                     </div>
                   </Col>
                 </Grid>
             </div>
           </div>
         </div>
          )}
        </>
      );
    };

  return (
    <>
      <div className="border-t pt-4">
        <div className="font-[700] text-[18px] text-[#001C44] mb-4 dark:text-gray-300">
          Client Performance by AM
        </div>
        <Grid
          className="gap-4 mb-4"
          numItems={1}
          numItemsSm={1}
          numItemsMd={4}
          numItemsLg={4}
        >
          <Col numColSpanLg={2}>
            <UserSearch
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
              selectedUserRole={selectedUserRole}
              setSelectedUserRole={setSelectedUserRole}
              setSelectedUserName={setSelectedUserName}
              level='am'
              role='am'
            />
          </Col>
          <Col numColSpanLg={1}>
            <ReactSelect
              value={duration}
              options={[
                { value: 0, label: '1 Year' },
                { value: 6, label: '6 months' },
                { value: 3, label: '3 Months' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setDuration(selectedOption);
              }}
            />
          </Col>
          <Col numColSpanLg={1}>
            <ReactSelect
              value={insightsStatusService}
              options={[
                { value: 'all', label: 'All Service' },
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setInsightsStatusService(selectedOption);
              }}
              placeholder="All Service"
            />
          </Col>
        </Grid>
        {/* <MissingIntegrationCards selectedUserId={selectedUserId} am={true}/> */}
        {userInsightsStatusAM.loading ? (
          <div className="w-full h-[242px] flex flex-col gap-5 justify-center items-center">
            <div className="w-1/2 bg-gray-300 rounded-full overflow-hidden">
              <div className="h-2 bg-blue-500 rounded-full animate-loading-bar" />
            </div>
            <div className="font-bold animate-pulse dark:text-white">
              Loading Data
            </div>
          </div>
        ) : (
          <ComboChart
                     data={userInsightsStatusArray}
                     index="date"
                     showLegend={false}
                        barSeries={{
                         categories: insightsStatusService?.value === 'all'
                         ? ['avgPerfomanceServiceSEO', 'avgPerfomanceServicePPC', 'avgPerfomanceServiceMeta']
                         : insightsStatusService?.value === 'seo_google'
                         ? ['avgPerfomanceServiceSEO']
                         : insightsStatusService?.value === 'ppc_google'
                         ? ['avgPerfomanceServicePPC']
                         : insightsStatusService?.value === 'social_meta'
                         ? ['avgPerfomanceServiceMeta']
                         : [],
                       colors: insightsStatusService?.value === 'all'
                         ? ['seo', 'ppc', 'meta']
                         : insightsStatusService?.value === 'seo_google'
                         ? ['seo']
                         : insightsStatusService?.value === 'ppc_google'
                         ? ['ppc']
                         : insightsStatusService?.value === 'social_meta'
                         ? ['meta']
                         : [],
                       yAxisWidth: 40,
                       // maxValue: selectedUserId  ? 100 : null,
                       maxValue: 100,
                       valueFormatter: (value) => dataFormatter(value, false, true),
                     }}
                     lineSeries={{
                       categories: insightsStatusService?.value === 'all'
                       ? (selectedUserId ? ['companyAverageTotalSEO', 'companyAverageTotalPPC', 'companyAverageTotalMeta'] : [])
                       : insightsStatusService?.value === 'seo_google'
                       ? (selectedUserId ? ['companyAverageTotalSEO'] : [])
                       : insightsStatusService?.value === 'ppc_google'
                       ? (selectedUserId ? ['companyAverageTotalPPC'] : [])
                       : insightsStatusService?.value === 'social_meta'
                       ? (selectedUserId ? ['companyAverageTotalMeta'] : [])
                       : [],
                       yAxisWidth: 40,
                       // maxValue: selectedUserId  ? 100 : null,
                       maxValue: 100,
                       valueFormatter: (value) => dataFormatter(value, false, true),
                     }}
                     customTooltip={userCustomTooltip}
                   />
         
        )}
      </div>
    </>
  );
};

export default PerformanceInsightsAM;
