import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  selectedUserId: string | null;
  setSelectedUserId: (userId: string | null) => void;
  selectedUserRole:string | null;
  setSelectedUserRole: (userId: string | null) => void;
  setSelectedUserName?: (userName: string | null) => void;
  level?: string | null;
  role?: string;
}

const UserSearch: React.FC<IProps> = ({ setSelectedUserId, setSelectedUserRole, level, role, setSelectedUserName, selectedUserId})  => {
  const { usersOptions, fetchUsers, clearOptions } = usePerformanceStore();
  const [searchTerm, setSearchTerm] = useState('');

  const [showDropdown, setShowDropdown] = useState(false);

  const debouncedFetchUsers = useCallback(
    debounce((term) => {
      fetchUsers(term, level, role)
    }, 500),
    [fetchUsers]
  );

  useEffect(() => {
    if (searchTerm.length >= 3) {
      clearOptions();
      debouncedFetchUsers(searchTerm);
    }
    if (searchTerm.length === 0) {
      clearOptions();
    }
  }, [searchTerm, debouncedFetchUsers, clearOptions]);

  useEffect(() => {
    if (selectedUserId === null && setSelectedUserName) {
      setSelectedUserName(null);
    }
  }, [selectedUserId, setSelectedUserName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setShowDropdown(true);
  };

  const handleOptionClick = (option) => {
    console.log(option)
    setSelectedUserId(option.id); // Set selected user ID
    setSearchTerm(`${option.first_name} ${option.last_name}`);
    setSelectedUserName(`${option.first_name} ${option.last_name}`);
    setShowDropdown(false);
    setSelectedUserRole(option.role._id)
  };

  return (
    <div className="relative w-full h-full">
      <div className="flex-grow">
        <div className="flex items-center w-full">
          <div className="relative w-full">
            <input
              type="search"
              className="block p-2 w-full z-50 text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-[#374151] dark:border-0 dark:text-white"
              placeholder="Search by user"
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value === '') {
                  setSelectedUserId(null);
                  setShowDropdown(false);
                }
              }}
              onFocus={() => setShowDropdown(true)}
            />
            {showDropdown && searchTerm.length !== 0 && (
              <div className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-full">
                <div className="max-h-60 overflow-y-scroll">
                  {usersOptions?.data?.map((option, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 text-[#000] cursor-pointer text-xs"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option?.first_name} {option?.last_name}
                    </div>
                  ))}
                </div>
                {usersOptions.loading && (
                  <div className="w-full h-full flex items-center justify-center py-4">
                    <Icon name="Loading" size={20} />
                  </div>
                )}
                {!usersOptions.loading && usersOptions?.data?.length === 0 && (
                  <div className="text-center text-xs text-gray-400 py-4">
                    No users found.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;