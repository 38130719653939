import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import useGoogleStore from '../../store/useGoogleStore';
import use3rdPartyStore from '../../store/use3rdPartyStore';
import useMetaStore from '../../store/useMetaStore';
import useAuthStore from '../../store/useAuthStore';
import useClientStore from '../../store/useClientStore';
import 'react-datepicker/dist/react-datepicker.css';
import { getStatus, isStaging } from '../../utils';
import AnalyticsPPC from '../../components/AnalyticsTabs/PPCv3';
import AnalyticsFB from '../../components/AnalyticsTabs/FbAdsv2';
import InternalPageHeader from '../../components/Dashboard/InternalPageHeader';
import ServiceNotAvailable from '../../components/ComingSoon';
import AnalyticsBingAds from '../../components/AnalyticsTabs/BingAds';
import useBingStore from '../../store/useBingStore';
import useDashboardStore from '../../store/useDashboardStore';
import AnalyticsSeoGA4 from './SeoGA4';
import DynamicPageHeaderGroup from '../../components/ReactGridComponents/Components/DynamicPageHeaderGroup';
interface PpcTab {
  index: string;
  id: string;
  title: string;
}
interface InternalAnalyticsProps {
  type?: string;
}

const InternalAnalyticsGridLayout: React.FC<InternalAnalyticsProps> = ({
  type,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef<any>(null);
  const { selectedClient, fetchClient } = useClientStore((state) => state);
  const { selectedDomain, setDomain } = useAuthStore((state) => state);
  const [isMounted, setIsMounted] = useState(false);
  const { sections } = useDashboardStore((state) => state);
  const [activePpcTab, setActiveTab] = useState(
    sections?.length > 0 ? sections[0] : null
  );

  const selectedTab =
    type === 'client'
      ? location.pathname.split('/')[2]
      : location.pathname.split('/')[3];

  const searchParams = new URLSearchParams(location.search);
  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');
  const id = searchParams.get('id');

  // // Set the active tab based on the location
  useEffect(() => {
    if (scrollRef.current) {
      setActiveTab(sections?.length > 0 ? sections[0] : []);
    }
  }, [location.pathname, location.search, selectedClient]);

  // Scroll to sepecific section
  const scrollToView = (id: string) => {
    const headerHeight = '220px';
    const targetElement = document.getElementById(id);

    if (targetElement) {
      targetElement.style.scrollMargin = headerHeight;
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Scroll to the active tab
  useEffect(() => {
    if (scrollRef.current) {
      scrollToView(activePpcTab?.order);
    }
  }, [activePpcTab]);

  // Fetch client data on mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id') || selectedDomain?.id;
    if (id !== 'No Domain' && !isMounted) {
      fetchClient(id).then((res: any) => {
        // fetchReportsData(res?.data);
        handleClientChange(res?.data);
      });
    }
  }, []);

  // set Domain/Client on url client id change
  useEffect(() => {
    // check if the selected domain and the search query id are the same and if not, update the selected domain
    if (selectedDomain?.id !== id && id !== null) {
      setDomain({ id: id, label: '' });
    }
  }, [id]);

  // fetch client date on domain change
  useEffect(() => {
    if (isMounted) {
      if (selectedDomain?.id !== 'No Domain') {
        fetchClient(selectedDomain?.id);
      } else {
        return;
      }
    }
  }, [selectedDomain]);

  // call handleClientChange on client id change
  useEffect(() => {
    handleClientChange();
  }, [selectedClient?.id]);

  // handle client change by adjusting the start and end date based on the cycle
  const handleClientChange = (paramClient?: any) => {
    const client = paramClient || selectedClient;
    const update = paramClient ? true : isMounted;
    const searchParams = new URLSearchParams(location.search);
    let startDate = searchParams.get('startDate');
    const localDate = JSON.parse(localStorage.getItem('seoStartDate'));
    let endDate;

    // Validate and initialize startMoment
    let startMoment =
      startDate && moment(startDate).isValid()
        ? moment(startDate)
        : localDate && moment(localDate).isValid()
          ? moment(localDate)
          : null;
    if (paramClient) {
      setIsMounted(true);
    }
    if (!startMoment && update) {
      // Default to the current date adjusted to the cycle
      startMoment = moment().subtract(1, 'month');
      if (client?.cycle === 'Cycle 2') {
        // Set it to the 15th of the current month and the 14th of the next month
        startDate = startMoment.set('date', 15).format('YYYY-MM-DD');
        endDate = startMoment
          .clone()
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
      } else if (client?.cycle === 'Cycle 1') {
        // Set to the 15th of the last month to the 14th of the current month
        startDate = startMoment
          .subtract(1, 'month')
          .set('date', 15)
          .format('YYYY-MM-DD');
        endDate = startMoment
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
      }
      searchParams.set('startDate', startDate);
      searchParams.set('endDate', endDate);
      navigate({ search: searchParams.toString() });
    } else {
      // Adjust the start and end dates based on the cycle
      if (client?.cycle === 'Cycle 2' && startMoment?.date() != 15 && update) {
        startDate = startMoment.set('date', 15).format('YYYY-MM-DD');
        endDate = startMoment
          .clone()
          .add(1, 'month')
          .set('date', 14)
          .format('YYYY-MM-DD');
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
        navigate({ search: searchParams.toString() });
      } else if (
        client?.cycle === 'Cycle 1' &&
        startMoment?.date() != 1 &&
        update
      ) {
        startDate = startMoment.set('date', 1).format('YYYY-MM-DD');
        endDate = startMoment.clone().endOf('month').format('YYYY-MM-DD');
        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        localStorage.setItem('seoStartDate', JSON.stringify(startDate));
        localStorage.setItem('seoEndDate', JSON.stringify(endDate));
        navigate({ search: searchParams.toString() });
      } else {
        if (update) {
          fetchReportsData(paramClient);
        }
      }
    }
  };
  // call fetchReportsData on start date change
  useEffect(() => {
    if (isMounted) {
      fetchReportsData();
    }
  }, [startDate]);

  // utility function to get formatted date from storage
  const getFormattedDateFromStorage = (key) => {
    const rawDate = localStorage.getItem(key);
    const json = rawDate ? JSON.parse(rawDate) : null;
    return rawDate ? moment(json).format('YYYY-MM-DD') : null;
  };
  // handle reports data fetch based send param or selected client
  const fetchReportsData = (paramClient?: any, resetCache?: boolean) => {
    const getDefaultSEODate = () => {
      const now = moment();
      const isCycle2 = selectedClient?.cycle?.toLowerCase() === 'cycle 2';
      if (isCycle2) {
        if (now.date() <= 14) {
          return {
            startDate: now.subtract(1, 'month').date(15).format('YYYY-MM-DD'),
            endDate: now.date(14).format('YYYY-MM-DD'),
          };
        } else {
          return {
            startDate: now.date(15).format('YYYY-MM-DD'),
            endDate: now.add(1, 'month').date(14).format('YYYY-MM-DD'),
          };
        }
      }
      return {
        startDate: now.startOf('month').format('YYYY-MM-DD'),
        endDate: now.endOf('month').format('YYYY-MM-DD'),
      };
    };

    const getDefaultPPCDate = () => {
      const startOfMonth = moment().startOf('month');
      return {
        startDate: startOfMonth.format('YYYY-MM-DD'),
        endDate: startOfMonth.clone().endOf('month').format('YYYY-MM-DD'),
      };
    };

    let seoDates = {
      startDate: startDate || getFormattedDateFromStorage('seoStartDate'),
      endDate: endDate || getFormattedDateFromStorage('seoEndDate'),
    };

    let ppcDates = {
      startDate: getFormattedDateFromStorage('startDate'),
      endDate: getFormattedDateFromStorage('endDate'),
    };

    // Set default dates if none are found
    seoDates =
      seoDates.startDate && seoDates.endDate ? seoDates : getDefaultSEODate();
    ppcDates =
      ppcDates.startDate && ppcDates.endDate ? ppcDates : getDefaultPPCDate();

    if (selectedClient?.id || paramClient?.id) {
      setIsMounted(true);

      // // Perform PPC && Meta related fetches
      // getGoogleAdds(
      //   ppcDates.startDate,
      //   ppcDates.endDate,
      //   paramClient,
      //   resetCache
      // );
      // fetchFbInsights(
      //   ppcDates.startDate,
      //   ppcDates.endDate,
      //   paramClient,
      //   resetCache
      // );
    }
  };

  const handleResetCache = () => {
    fetchReportsData(selectedClient, true);
  };

  // redirect to home if not on staging
  useEffect(() => {
    if(isMounted) {
      if(!isStaging()) {
        navigate('/')
      }
    }
  },[isMounted])

  const isInternalAnalytics = location.pathname.includes('/client/analytics/');
  return (
    <div
      className="w-full bg-[#D2EEFF] font-inter"
      ref={!isInternalAnalytics ? scrollRef : null}
    >
      <DynamicPageHeaderGroup
        subtitle="Monthly Activity Overview"
        customClass="sticky top-[110px] sm:top-[64px] sm:px-[2.5rem] z-[12]"
        activeTab={activePpcTab}
        setActiveTab={setActiveTab}
        handleScroll={scrollToView}
        handleResetCache={handleResetCache}
        type={type}
        // data={} // dynamic data here
      />

      <div className="flex flex-col px-3 pt-[70px] sm:pt-0 sm:px-[2.5rem] py-4 gap-2 lg:gap-4 dark:bg-[#0C111D] ">
        {selectedTab === 'seo-ga4' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ServiceNotAvailable
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsSeoGA4
                clientLoading={selectedClient?.loading}
                category={selectedClient?.category_type}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </>
        )}
        {selectedTab === 'paid-search' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ServiceNotAvailable
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsPPC clientLoading={selectedClient?.loading} />
            )}
          </>
        )}
        {selectedTab === 'microsoft-ads' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ServiceNotAvailable
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsBingAds />
            )}
          </>
        )}
        {selectedTab === 'fb-ads' && (
          <>
            {selectedDomain?.id === 'No Domain' ? (
              <ServiceNotAvailable
                title="No Client Domain"
                message="Before We Venture Further, Let's Find Our Common Ground: Please Search and Select a Client First."
              />
            ) : (
              <AnalyticsFB />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InternalAnalyticsGridLayout;
