import { useMemo } from "react";
import InfoTooltip from "../../AnalyticsSections/AnalyticsInfoTooltip";
import Tooltip from "../../HelpTooltip";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";



interface IProps {
  data?: any;
  isLoading?: boolean;
}

const GSCKeywordsAndPages = ({
  data,
  isLoading
}: IProps) => {
  
  const tableData = data;
  const getKey = tableData?.length > 0 && Object.keys(tableData[0])[0];
  

  const getKeyData = useMemo(() => {
    if (!tableData || tableData.length === 0) return null;
    if(getKey === "page") {
      return {
          id: 'Page',
          header: (props) => {
            const headerId = props?.table?.options?.state?.sorting[0]?.id;
            const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
            if (headerId === 'Page' && sortDirection === false) {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                  <div>Page</div>
                  <Icon name="ChevronUp" color="#808DA1" size={20} />
                </div>
              );
            }
  
            if (headerId === 'Page' && sortDirection === true) {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                  <div>Page</div>
                  <Icon name="ChevronDown" color="#808DA1" size={20} />
                </div>
              );
            }
  
            if (headerId !== 'Page' || headerId === 'Page') {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[36vw] h-fit pl-4">
                  <div>Page</div>
                  <Icon name="Sort" />
                </div>
              );
            }
          },
          accessorKey: 'page',
          cell: (props: any) => (
            <div className="flex justify-start font-inter w-[180px] sm:w-[36vw] pl-4 h-fit">
              <Tooltip content={props.getValue()} position="right">
                <div className="truncate max-w-[150px] sm:max-w-[36vw] overflow-hidden dark:text-white">
                  {props.getValue()}
                </div>
              </Tooltip>
            </div>
          ),
      }
    } else {
        return {
          id: 'Keyword',
          header: (props) => {
            const headerId = props?.table?.options?.state?.sorting[0]?.id;
            const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
            if (headerId === 'Keyword' && sortDirection === false) {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[30vw] h-fit pl-4">
                  <div>Keyword</div>
                  <Icon name="ChevronUp" color="#808DA1" size={20} />
                </div>
              );
            }
  
            if (headerId === 'Keyword' && sortDirection === true) {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[30vw] h-fit pl-4">
                  <div>Keyword</div>
                  <Icon name="ChevronDown" color="#808DA1" size={20} />
                </div>
              );
            }
  
            if (headerId !== 'Keyword' || headerId === 'Keyword') {
              return (
                <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[30vw] h-fit pl-4">
                  <div>Keyword</div>
                  <Icon name="Sort" />
                </div>
              );
            }
          },
          accessorKey: 'query',
          cell: (props: any) => (
            <div className="flex justify-start font-inter w-[150px] sm:w-[30vw] pl-4 h-fit">
              <Tooltip content={props.getValue()} position="right">
                <div className="truncate w-[150px] sm:max-w-[30vw] overflow-hidden dark:text-white">
                  {props.getValue()}
                </div>
              </Tooltip>
            </div>
          ),
        }
      }
  },[tableData])

  const reportData = useMemo(() => {
    const tableHeader = [
      {...getKeyData},
      {
        id: 'Impressions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impressions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Impressions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impressions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Impressions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impressions' || headerId === 'Impressions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Impressions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'Position',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Position' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Position' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Position' || headerId === 'Position') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'position',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit dark:text-white">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
    ];

    const data = !tableData?.error && (tableData || []).map((resource: any) => {
      return {
        [getKey]: resource[getKey],
        impressions: resource?.impressions,
        clicks: resource?.clicks,
        ctr: resource?.ctr,
        position: resource?.position,
      };
    });

    return { tableHeader, data };
  }, [tableData]);
  
  
  return (
    <div className="relative overflow-auto max-w-full">
      <AnalyticsTable
        data={reportData?.data ? reportData?.data : []}
        columns={reportData?.tableHeader}
        loading={data?.loading}
        totalDocs={reportData?.data ? reportData?.data.length : 'N/A'}
        sortByColumnId="Impressions"
        pagination={true}
        // error={error}
        // retryMethod={retryMethod}
      />
    </div>
  )
}

export default GSCKeywordsAndPages;