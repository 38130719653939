import { useMemo } from "react";
import InfoTooltip from "../../AnalyticsSections/AnalyticsInfoTooltip";
import Tooltip from "../../HelpTooltip";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";
import moment from "moment";
import useClientStore from "../../../store/useClientStore";

interface IProps {
  data?: any;
  isLoading?: boolean;
}

const CompetitorsInsights = ({
  data,
  isLoading
}: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const competitorsRes = data || [];

  const competitorsData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Domain',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Domain' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
                <div>Domain</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Domain' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
                <div>Domain</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Domain' || headerId === 'Domain') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[160px] sm:w-[18vw] h-fit pl-4">
                <div>Domain</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Domain',
        cell: (props: any) => {
          const isSameDomain =
            props?.row?.original?.Domain === selectedClient?.domain;
          return (
            <div
              className={`flex justify-start font-inter w-[160px] sm:w-[18vw] pl-4 h-fit ${isSameDomain ? 'bg-[#D0EDFF]' : 'bg-white  dark:bg-gray-800'}`}
            >
              <Tooltip content={props.getValue()} position="right">
                <div className="truncate max-w-[16vw] overflow-hidden text-[#0029FF] ">
                  {props.getValue()}
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
        id: 'Organic Traffic',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Organic Traffic' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit">
                <div>Organic Traffic</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Organic Traffic' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit">
                <div>Organic Traffic</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Organic Traffic' ||
            headerId === 'Organic Traffic'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit">
                <div>Organic Traffic</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Organic Traffic',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[15vw]  h-fit">
            <div className="truncate w-[150px] text-center sm:max-w-[15vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Organic Keywords',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Organic Keywords' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Keywords</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Organic Keywords' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Keywords</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Organic Keywords' ||
            headerId === 'Organic Keywords'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Keywords</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Organic Keywords',
        cell: (props: any) => {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
              <div className="w-[35px] text-center">
                {formatNumberWithCommas(props.getValue())}
              </div>
            </div>
          );
        },
      },
      {
        id: 'Organic Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Organic Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Organic Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Organic Cost' || headerId === 'Organic Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Organic Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Organic Cost',
        cell: (props: any) => {
          return (
            <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
              ${formatNumberWithCommas(props.getValue())}
            </div>
          );
        },
      },
      {
        id: 'Adwords Keywords',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Adwords Keywords' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Adwords Keywords</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Adwords Keywords' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Adwords Keywords</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Adwords Keywords' ||
            headerId === 'Adwords Keywords'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit">
                <div>Adwords Keywords</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Adwords Keywords',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[12vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Common Keywords',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Common Keywords' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4">
                <div>Common Keywords</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Common Keywords' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4">
                <div>Common Keywords</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Common Keywords' ||
            headerId === 'Common Keywords'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[12vw] h-fit pl-4">
                <div>Common Keywords</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Common Keywords',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[12vw] pl-4 h-fit">
            <div className="truncate w-fit sm:max-w-[12vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Competitor Relevance',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Competitor Relevance' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Competitor Relevance</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Competitor Relevance' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Competitor Relevance</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Competitor Relevance' ||
            headerId === 'Competitor Relevance'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Competitor Relevance</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Competitor Relevance',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[200px] sm:w-[14vw] pl-4 h-fit">
            <div className="truncate w-fit sm:max-w-[14vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'SERP Features Positions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'SERP Features Positions' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Positions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'SERP Features Positions' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Positions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'SERP Features Positions' ||
            headerId === 'SERP Features Positions'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Positions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'SERP Features Positions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[220px] sm:w-[14vw] pl-4 h-fit">
            <div className="truncate w-fit sm:max-w-[14vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'SERP Features Traffic',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'SERP Features Traffic' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'SERP Features Traffic' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'SERP Features Traffic' ||
            headerId === 'SERP Features Traffic'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'SERP Features Traffic',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[220px] sm:w-[14vw] pl-4 h-fit">
            <div className="truncate w-fit sm:max-w-[14vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'SERP Features Traffic Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'SERP Features Traffic Cost' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'SERP Features Traffic Cost' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'SERP Features Traffic Cost' ||
            headerId === 'SERP Features Traffic Cost'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[220px] sm:w-[14vw] h-fit pl-4">
                <div>SERP Features Traffic</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'SERP Features Traffic Cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[220px] sm:w-[14vw] pl-4 h-fit">
            <div className="truncate w-fit sm:max-w-[14vw] overflow-hidden">
              {formatNumberWithCommas(props.getValue())}
            </div>
          </div>
        ),
      },
    ];

    const data = competitorsRes?.length > 0 && (competitorsRes || []).map((resource: any) => {
      return {
        Domain: resource?.Domain || '-',
        'Organic Traffic': parseInt(resource?.['Organic Traffic'], 10) || 0,
        'Organic Keywords': parseInt(resource?.['Organic Keywords'], 10) || 0,
        'Organic Cost': parseFloat(resource?.['Organic Cost']) || 0,
        'Adwords Keywords': parseInt(resource?.['Adwords Keywords'], 10) || 0,
        'SERP Features Positions':
          parseInt(resource?.['SERP Features Positions'], 10) || 0,
        'SERP Features Traffic':
          parseInt(resource?.['SERP Features Traffic'], 10) || 0,
        'SERP Features Traffic Cost':
          parseFloat(resource?.['SERP Features Traffic Cost']) || 0,
        'Competitor Relevance': resource?.['Competitor Relevance'] || '-',
        'Common Keywords': parseInt(resource?.['Common Keywords'], 10) || 0,
      };
    });

    return {
      tableHeader,
      data,
    };
  }, [competitorsRes]);

  return (
    <div className="relative test">
      <AnalyticsTable
          data={competitorsData?.data ? competitorsData.data : []}
          columns={competitorsData?.tableHeader}
          totalDocs={
            competitorsData.data ? competitorsData?.data?.length : 'N/A'
          }
          sortByColumnId="Organic Traffic"
          loading={isLoading}
          pagination={true}
          selectedClient={selectedClient}
          highlightRow={true}
          // error={error}
          // retryMethod={retryFetchSemrushCompetitor}
        />
    </div>
  )
}

export default CompetitorsInsights;