import { Trash01, Xclose } from '../../../assets/icons';

interface ModalProps {
  user?: any;
  onClose?: () => void;
  onDelete?: () => void;
}
const ResetTemplateConfirmation: React.FC<ModalProps> = ({
  user,
  onClose,
  onDelete,
}) => {

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
      className="absolute flex flex-col justify-center items-start w-[30%] h-[300px] p-6 z-100 bg-white dark:bg-[#262627]  dark:shadow-bs-card border-2 border-[#FFFFFF] rounded-md"
    >
      <div className="flex items-center justify-between w-full">
        <div className=" p-3 rounded-full bg-[#FEE4E2] border-8 border-[#FEF3F2] cursor-pointer">
          <Trash01 />
        </div>
        <div className="cursor-pointer" onClick={onClose}>
          <Xclose />
        </div>
      </div>
      <span className="font-inter text-[#101828] text-xl font-semibold mt-4 dark:text-[#E6EFF5]">
        Reset template?
      </span>
      <span className="font-inter w-[90%] text-[#475467] text-sm mt-2 dark:text-[#E6EFF5]">
        Are you sure you want to reset this template? This action cannot be undone.
      </span>

      <div className="flex items-center justify-center gap-4 mt-8 w-full mb-2">
        <button
          onClick={onClose}
          className="btn w-full border border-[#808EA2] bg-[#FFF] text-[#001C44] text-base py-2.5 px-4 rounded-lg font-semibold"
        >
          Cancel
        </button>
        <button
          onClick={onDelete}
          className="btn w-full border border-[#D92D20] bg-[#D92D20] text-[#FFF] text-base py-2.5 px-4 rounded-lg font-semibold"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ResetTemplateConfirmation;
