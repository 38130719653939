import { create } from 'zustand';
// import Api from '../api';
import { devtools } from 'zustand/middleware';
import Api from '../api';
import {
  emptyLayoutCharts,
  getInitialLayout,
} from '../components/ReactGridComponents/constants';
import { Dashboard } from '../api/dashboard';
import useAuthStore from './useAuthStore';
import { convertIdKey } from '../utils/common';

interface IDashboardStore {
  sections: any[];
  dashboards: any[];
  templates: any[];
  graphData: any;
  dashboard_template_id: string;
  templateLoading: boolean;
  templateCreationLoading: boolean;
  integrationLoading: boolean;
  isSectionsFetched: boolean;
  message: '';
  error: boolean;
  loading: boolean;
  errorMessages: '';
  isGraphDataLoading: boolean;

  isAddingNewSection: boolean;

  fetchDashboardTemplates: () => void;
  createDashboardTemplate: (data: any, clientId: string) => void;
  createDashboardAndIntegrateTemplate: (templateId: string, clientId: string, data: any) => void;

  fetchDashboardTemplate: (id: string) => void;
  fetchDashboardGraph: (id: string, data: any) => void;

  fetchDashboard: (dashboard_page: string) => void;
  createDashboard: (data: any) => void;
  updateDashboard: (dashboardId: string, data: any) => void;

  fetchDashboardPages: (id: string) => void;
  createDashboardSection: (data: any) => void;
  createDashboardSectionViaItem: (data: any) => void;
  updateDashboardPage: (id: string, dashboardId: string, data: any) => void;

  updateDashboardLayout: (
    sectionId: string,
    dashboardId: string,
    layout: string
  ) => void;
  addPlaceholder: (id: string, dashboardId: string) => void;

  addNewSection: (value: boolean) => void;
  
  deleteSection: (id: string) => void;

  deleteContent: (id: string) => void;
}

const useDashboardStore = create<IDashboardStore>(
  devtools((set, get) => ({
    sections: [],
    dashboards: [],
    templates: [],
    graphData: {},
    dashboard_template_id: '',
    message: '',
    error: false,
    loading: false,
    templateLoading: false,
    templateCreationLoading: false,
    integrationLoading: false,
    errorMessages: '',
    isSectionsFetched: false,
    isGraphDataLoading: false,
    isAddingNewSection: true,

    fetchDashboardTemplates: async (id: string) => {
      set(() => ({ templateLoading: true }));
      const response = await Api.Dashboard.getDashboardTemplates();

      if (response) {
        set(() => ({
          templates: [
            ...response,
            {
              _id: 'Create',
              title: 'Create a new Template',
              description: `Start fresh and design a brand new template, giving you full control over every detail.`,
            },
          ],
          templateLoading: false,
        }));
      }
    },

    createDashboardTemplate: async (data: any, clientId: string) => {
      set(() => ({ templateCreationLoading: true }));
      const create = await Api.Dashboard.createDashboardTemplate(data);
      console.log(create, 'create');
      if (create) {
        // integrate new dashboard template id
        const integrate = await Api.Dashboard.integrateDashboardToClient(
          clientId,
          {
            dashboard_template_id: create._id,
          }
        );
        console.log(integrate, 'integrate');
        set(() => ({ templateCreationLoading: false }));
      }
    },
    createDashboardAndIntegrateTemplate: async (templateId: string,clientId: string, data: any) => {
      set(() => ({ templateCreationLoading: true }));
      const getTemplateSections = await Api.Dashboard.getDashboardSection(templateId)
      
      const newRegularTemplate = {
        ...data,
        sections: [...getTemplateSections],
      };
      
      const create = await Api.Dashboard.createDashboardTemplate(newRegularTemplate);
      
      if (create) {
        // integrate new dashboard template id
        const integrate = await Api.Dashboard.integrateDashboardToClient(
          clientId,
          {
            dashboard_template_id: create._id,
          }
        );
        set(() => ({ templateCreationLoading: false }));
      }
    },

    fetchDashboardTemplate: async (id: string) => {
      set(() => ({ loading: true }));
      const response = await Api.Dashboard.getDashboardTemplate(id);
      
      if (response) {
        set(() => ({
          dashboard_template_id: response._id,
          sections: response.sections,
          loading: false,
        }));
      }
    },

    fetchDashboardGraph: async (id: string, data: any) => {
      set(() => ({ isGraphDataLoading: true }));
      const response = await Api.Dashboard.getDashboardGraph(id, data);

      if (response && response?.contents?.length > 0) {
        set((state) => ({
          graphData: {
            ...state.graphData,
            ...response?.contents[0]?.layout_charts.reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {}),
          },
          isGraphDataLoading: false,
        }));
      }
    },

    fetchDashboard: async (dashboard_page: string) => {
      set(() => ({ loading: true }));
      const response = await Api.Dashboard.getDashboard(dashboard_page);
      // console.log(response,'response123');

      if (response?.length > 0) {
        set((state) => ({
          sections: state.sections.map((p) =>
            p.id === response[0]?.dashboard_page
              ? {
                  ...p,
                  dashboards: response[0],
                }
              : p
          ),
          dashboards: [...state.dashboards, ...response],
          loading: false,
        }));
      }
    },
    createDashboard: async (data) => {
      set(() => ({ loading: true }));
    },
    updateDashboard: async (dashboardId, data) => {
      set(() => ({ loading: true }));

      set((state) => ({
        sections: state.sections.map((p) =>
          p.id === data?.dashboard_page
            ? {
                ...p,
                dashboards: [
                  {
                    ...state.sections.dashboard,
                    ...data,
                  },
                ],
              }
            : p
        ),
        loading: false,
      }));

      await Dashboard.updateDashboardContent(dashboardId, data);
    },
    updateDashboardLayout: async (
      sectionId: string,
      dashboardId: string,
      layout: string
    ) => {
      if (dashboardId) {
        await Dashboard.updateDashboardContent(dashboardId, layout);
      }
    },
    fetchDashboardPages: async (id: string) => {
      set(() => ({ loading: true, isSectionsFetched: false }));
      const sectionsResponse = await Api.Dashboard.getDashboardPages(id);
      console.log(sectionsResponse,'fetch 2')
      set(() => ({
        sections: convertIdKey(sectionsResponse),
        loading: false,
        isSectionsFetched: true,
      }));

      // BE returns dashboard in pages now
      // if(sectionsResponse?.length > 0) {
      //   sectionsResponse.map(async (p) => {
      //     const responseDashboard = await Api.Dashboard.getDashboard(p.id);
      //     console.log(responseDashboard,'response123gg');

      //     if(responseDashboard?.length > 0) {
      //       set((state) => ({
      //         sections: state.sections.map( p =>
      //           p.id === responseDashboard[0]?.dashboard_page ? {
      //             ...p,
      //             dashboards: responseDashboard[0]
      //           } : p
      //         ),
      //         loading: false
      //       }));
      //     }
      //   });
      // }
    },
    createDashboardSection: async (data: any) => {
      set(() => ({ loading: true }));
      const contentsData = {
        status: 'active',
        layout_group: data.title.replace(/\s+/g, '-').toLowerCase() || '',
        layout: JSON.stringify(getInitialLayout()),
        layout_charts: emptyLayoutCharts,
      }
      let dashboardData = {
        ...data,
        contents: [ { ...contentsData } ]
      };
      const create = await Api.Dashboard.createDashboardSection(dashboardData);
      // get section details
      // const response = await Api.Dashboard.getDashboardSection(create.dashboard_template_id)
      // const section = response.find( r => r._id === create.id);

      set((state) => ({
        sections: [
          ...state.sections,
          {
            ...convertIdKey(create)
          },
        ],
        isAddingNewSection: false,
        loading: false,
      }));
    },
    createDashboardSectionViaItem: async (data: any) => {
      set(() => ({ loading: true }));
      const create = await Api.Dashboard.createDashboardSection(data);
      
      // get section data
      // const response = await Api.Dashboard.getDashboardSection(create.dashboard_template_id)
      // const section = response.find( r => r._id === create.id);
      set((state) => ({
        sections: [
          ...state.sections,
          {
            ...convertIdKey(create)
          },
        ],
        isAddingNewSection: false,
        loading: false,
      }));
    },
    updateDashboardPage: async (id: string, dashboardId: string, data: any) => {
      const update = await Dashboard.updateDashboardSection(id, data);
    },
    addPlaceholder: (id: string, dashboardId: string) => {
      set((state) => ({
        sections: state.sections.map((p) =>
          p.id === id[0]
            ? {
                ...p,
                dashboards: {},
              }
            : p
        ),
        loading: false,
      }));
    },
    addNewSection: () => {
      set((state) => ({
        isAddingNewSection: true,
        sections: [
          ...state.sections,
          {
            id: Date.now(),
            title: 'New Section',
            order: state.sections.length + 1,
            contents: [],
          },
        ],
      }));
    },
    deleteSection: async (id: string) => {
      await Dashboard.deleteSection(id);
      
      set((state) => ({
        sections: state.sections.filter( s => s._id !== id)
      }));
    },
    deleteContent: async (id: string) => {
      await Dashboard.deleteContent(id);
      set((state) => ({
        sections: []
      }));
    }
  })) as any
);

export default useDashboardStore;
