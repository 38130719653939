import { create } from 'zustand';
import Api from '../api';

interface Status {
  loading: boolean;
  error: boolean;
}

interface KeywardReach {
  top_1_keywords_reach: number;
  top_3_keywords_reach: number;
  top_10_keywords_reach: number;
}

interface Client {
  id: string;
  name: string;
  domain: string;
}

interface Ga4 {
  new_users: number;
  organic_users: number;
}
interface Gsc {
  clicks: number;
  impressions: number;
}

interface Data {
  name: any;
  account_manager: string;
  ahrefs: KeywardReach;
  client: Client;
  consultant: string;
  cycle: string;
  ga4: Ga4;
  gad: string;
  gsc: Gsc;
  id: string;
  manager: string;
  number_of_positive: string;
  organic_conversions: string;
  organic_revenue: string;
  revenue: string;
  semrush: KeywardReach;
  service: string;
  start_date: string;
}

interface Performance {
  total_docs: number;
  limit?: number;
  total_pages: number;
  page: number;
  data: Data[];
}

interface Summary {
  green: number;
  amber?: number;
  red: number;
}

interface Filters {
  integration?: string;
  cycle?: string;
  report_date?: any;
  exclude?: string[];
  page?: number;
  limit?: number;
  all?: boolean;
  sortBy?: string;
  service?: string;
  sortOrder?: string;
  role?: string;
  search?: string;
  status?: string;
  clients?: string[];
  users?: string[];
  user?: string;
  clientPerformanceIds?: string[];
  clientId?: string;
  userId?: string;
}

interface Store {
  performance: Status & Partial<Performance>;
  summary: Status & Partial<Summary>;
  filters: Filters;
  clientsOptions: any;
  usersOptions: any;
  insightsOverall: any;
  insightsService: any;
  insightsStatus: any;
  userInsightsStatus: any;
  userInsightsStatusAM: any;
  insightsSummary: any;
  insightsSummaryAM: any;
  regenerating: boolean;
  generatedClientId: any;
  comments: any;
  clearOptions: () => void;
  setClientsOptions: (options: any) => void;
  setUsersOptions: (options: any) => void;
  setGeneratedClientId: (options: any) => void;
  fetchClients: (search: string) => Promise<{ status?: boolean }>;
  fetchUsers: (search: string, level?:string, role?:string) => Promise<{ status?: boolean }>;
  fetchPerformance: (Filters: Filters) => Promise<{ status?: boolean }>;
  regeneratePerformance: (Filters: Filters) => Promise<{ status?: boolean }>;
  generatePerformance: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchInsightsOverall: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchInsightsSummary: (Filters: Filters, am?: boolean) => Promise<{ status?: boolean }>;
  fetchInsightsService: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchInsightsStatus: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchAllStaffStatus: () => Promise<{ status?: boolean }>;
  fetchInsightsUserStatus: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchUserStaffStatus: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchAMUserStaffStatus: (Filters: Filters) => Promise<{ status?: boolean }>;
  fetchSummary: (filters: Filters) => Promise<{ status: boolean }>;
  createPerformanceComment: (
    id: string,
    data: any
  ) => Promise<{ status: boolean }>;
  fetchPerformanceComments: (
    id: string,
    filters: Filters
  ) => void
  deletePerformanceComment: (id: string, commentId: string) => void;
}

const initialStatus: Status = {
  loading: true,
  error: false,
};

const usePerformanceStore = create<Store>((set, get) => ({
  performance: initialStatus,
  summary: initialStatus,
  insightsSummary: initialStatus,
  insightsSummaryAM: initialStatus,
  userInsightsStatus: initialStatus,
  userInsightsStatusAM: initialStatus,
  regenerating: false,
  clientsOptions: [],
  usersOptions: [],
  insightsOverall: [],
  insightsService: [],
  insightsStatus: [],
  generatedClientId: '',
  comments: [],

  filters: {
    service: '',
    integration: '',
    cycle: '',
    report_date: '',
    exclude: [],
    clients: [],
    users: [],
    clientPerformanceIds: [],
  },
  fetchAllStaffStatus: async () => {
    try {
      set(() => ({ insightsStatus: initialStatus }));
      const response = await Api.Performance.getAllStaffStatus();
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ insightsStatus: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchUserStaffStatus: async (filters: Filters) => {
    try {
      set(() => ({ userInsightsStatus: initialStatus }));
      const response = await Api.Performance.getUserStaffStatus(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ userInsightsStatus: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchAMUserStaffStatus: async (filters: Filters) => {
    try {
      set(() => ({ userInsightsStatusAM: initialStatus }));
      const response = await Api.Performance.getUserStaffStatus(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ userInsightsStatusAM: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },


  fetchInsightsSummary: async (filters: Filters, am = false) => {
    try {
      if(am){
        set(() => ({ insightsSummaryAM: initialStatus }));
        const response = await Api.Performance.getInsightsSummary(filters);
        const logResponse = {
          ...response,
          loading: false,
          error: response.error,
        };
        if (logResponse.error) {
          throw logResponse.error;
        } else {
          set(() => ({ insightsSummaryAM: logResponse }));
          return { status: true };
        }
      }else {
        set(() => ({ insightsSummary: initialStatus }));
        const response = await Api.Performance.getInsightsSummary(filters);
        const logResponse = {
          ...response,
          loading: false,
          error: response.error,
        };
        if (logResponse.error) {
          throw logResponse.error;
        } else {
          set(() => ({ insightsSummary: logResponse }));
          return { status: true };
        }
      }
    } catch (error) {
      throw error;
    }
  },
  fetchInsightsStatus: async (filters: Filters) => {
    try {
      set(() => ({ insightsStatus: initialStatus }));
      const response = await Api.Performance.getInsightsStatus(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ insightsStatus: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchInsightsUserStatus: async (filters: Filters) => {
    try {
      set(() => ({ userInsightsStatus: initialStatus }));
      const response = await Api.Performance.getInsightsUserStatus(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ userInsightsStatus: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchInsightsService: async (filters: Filters) => {
    try {
      set(() => ({ insightsService: initialStatus }));
      const response = await Api.Performance.getInsightsService(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ insightsService: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },

  fetchInsightsOverall: async (filters: Filters) => {
    try {
      set(() => ({ insightsOverall: initialStatus }));
      const response = await Api.Performance.getInsightsOverall(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ insightsOverall: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchSummary: async (filters: Filters) => {
    try {
      set(() => ({ summary: initialStatus }));
      const response = await Api.Performance.getSummary(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ summary: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchPerformance: async (filters: Filters) => {
    try {
      set(() => ({ performance: initialStatus }));
      const response = await Api.Performance.getPerformance(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ performance: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  generatePerformance: async (filters: Filters) => {
    try {
      set(() => ({ regenerating: true }));
      const response = await Api.Performance.generatePerformance(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        set(() => ({ regenerating: false }));
        throw logResponse.error;
      } else {
        // set(() => ({ regenerating: false }));
        return logResponse;
      }
    } catch (error) {
      throw error;
    }
  },

  regeneratePerformance: async (filters: Filters) => {
    try {
      const response = await Api.Performance.regeneratePerformance(filters);
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        return logResponse;
      }
    } catch (error) {
      throw error;
    }
  },

  fetchClients: async (search: string) => {
    try {
      set(() => ({ clientsOptions: initialStatus }));
      const response = await Api.Client.getClients({ search: search });
      const logResponse = {
        loading: false,
        ...response,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ clientsOptions: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  fetchUsers: async (search: string, level: string = 'omg', role: string = '') => {
    try {
      set(() => ({ usersOptions: initialStatus }));
      const response = await Api.User.getUsers({
        search: search,
        level: level, // level defaults to 'omg' if not provided
        gurulytics_role: role,
        role: role,
      });
      const logResponse = {
        ...response,
        loading: false,
        error: response.error,
      };
      if (logResponse.error) {
        throw logResponse.error;
      } else {
        set(() => ({ usersOptions: logResponse }));
        return { status: true };
      }
    } catch (error) {
      throw error;
    }
  },
  
  setClientsOptions: (options: any) => {
    set(() => ({ clientsOptions: options }));
  },
  setUsersOptions: (options: any) => {
    set(() => ({ usersOptions: options }));
  },

  setGeneratedClientId: (id: any) => {
    set(() => ({ generatedClientId: id }));
  },
  clearOptions: () => {
    set(() => ({ clinetsOptions: [], usersOptions: [] }));
  },

  fetchPerformanceComments: async (id: string, filters: Filters) => {
    set(() => ({ comments: initialStatus }));
    const response = await Api.Performance.getPerformanceComment(id, filters);
    
    set(() => ({ comments: response }));
  },
  createPerformanceComment: async (id: string, data: any) => {
    try {
      await Api.Performance.createComment(id, data);
      const response = await Api.Performance.getPerformanceComment(id, {
        page: 1,
        limit: 20,
        sort: 'desc'
      });
      set(() => ({ comments: response }));

      return { status: true };
    } catch (error) {
      throw error;
    }
  },
  deletePerformanceComment: async (id: string, commentId: string) => {
    try {
      await Api.Performance.deleteComment(id,commentId);
      set((state) => ({ comments: state.comments.data.filter( c => c._id !== commentId) }));
    } catch (error) {
      throw error;
    }
  },
}));

export default usePerformanceStore;
