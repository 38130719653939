import { useEffect, useState } from 'react';
import {
  Grid,
  Col,
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanels,
  TabPanel,
} from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import { getServiceLabel, formatNumber } from '../../../utils/common';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const TopAndUnderServicePerformance = () => {
  const { fetchInsightsOverall, insightsOverall } = usePerformanceStore(
    (state) => state
  );
  const [topAndUnderPerformance, setTopAndUnderPerformance] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Google SEO',
    value: 'seo_google',
  });

  const lastMonth = moment().subtract(1, 'month').startOf('month').toDate();
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const [topPerformanceDate, setTopPerformanceDate] = useState(lastMonth);

  useEffect(() => {
    handleFetchInsightsOverall();
  }, [topAndUnderPerformance, topPerformanceDate]);

  const handleFetchInsightsOverall = () => {
    const payload = {
      report_date: moment(topPerformanceDate).format('MM-YYYY'),
      service: topAndUnderPerformance?.value,
      limit: 5,
    };
    fetchInsightsOverall(payload);
  };

  const leadGenTopData = insightsOverall?.['Lead Gen']?.top;

  const ecommerceTopData = insightsOverall?.['Ecommerce']?.top;

  const leadGenUnderPerformanceData = insightsOverall?.['Lead Gen']?.low;

  const ecommerceUnderPerformanceData = insightsOverall?.['Ecommerce']?.low;

  const handleFilterChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);

    if (actionMeta === 'TopDate') {
      setTopPerformanceDate(selectedOption);
      newUrl.searchParams.set('date', moment(selectedOption).format('MM-YYYY'));
    }

    window.history.pushState({}, '', newUrl);
  };

  return (
    <div>
      <div className="flex justify-between items-center border-y py-6">
        <div className="font-[700] text-[18px] text-[#001C44] dark:text-gray-300">
          Overall Performance
        </div>
        <div className="flex gap-4 w-fit">
          <div className="font-inter w-[140px]">
            <DatePicker
              selected={moment(topPerformanceDate, 'YYYY-MM').toDate()}
              onChange={(date) => {
                if (date !== null) {
                  handleFilterChange(date, 'TopDate');
                }
              }}
              dateFormat="MMM yyyy"
              maxDate={maxDate}
              showMonthYearPicker
              className="react-datepicker__month-container text-center h-[38px]  border border-[#808EA2] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none font-inter rounded-lg text-[14px] text-[#525252]"
            />
          </div>
          <div className="w-[150px]">
            <ReactSelect
              value={topAndUnderPerformance}
              options={[
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setTopAndUnderPerformance(selectedOption);
              }}
              placeholder="All Services"
              controlBgColor="#0029FF !important"
              textColor="#ffffff !important"
            />
          </div>
        </div>
      </div>
      <div className="dark:bg-[#222732] p-4 rounded-lg mt-2">
        <TabGroup>
          <TabList variant="line">
            <Tab value="1">Lead Gen</Tab>
            <Tab value="2">Ecommerce</Tab>
          </TabList>
          {insightsOverall?.loading ? (
            <div className="w-full h-[350px] flex flex-col gap-5 justify-center items-center">
              <div className="w-1/2 bg-gray-300 rounded-full overflow-hidden">
                <div className="h-2 bg-blue-500 rounded-full animate-loading-bar" />
              </div>
              <div className="font-bold animate-pulse dark:text-white">
                Loading Data
              </div>
            </div>
          ) : (
            <TabPanels>
              <TabPanel>
                <Grid numItems={2} className="gap-8">
                  <Col>
                    <div className="font-[700] text-[18px] dark:text-white my-4">
                      Top Performance
                    </div>
                    {leadGenTopData && leadGenTopData.length > 0 ? (
                      leadGenTopData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[30vw] py-2">
                            <p
                              className="font-[600] text-sm dark:text-white truncate"
                              title={data?.client?.name}
                            >
                              {data?.client?.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div
                            className={
                              data.performance?.score > 50
                                ? 'text-[#3CCB7F] text-sm'
                                : 'text-[#FF0000] text-sm'
                            }
                          >
                            {formatNumber(data.performance?.score)}%
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                        <div>No data</div>
                      </div>
                    )}
                  </Col>
                  <Col>
                    <div className="font-[700] text-[18px] dark:text-white my-4">
                      Under Performance
                    </div>
                    {leadGenUnderPerformanceData &&
                    leadGenUnderPerformanceData.length > 0 ? (
                      leadGenUnderPerformanceData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[30vw] py-2">
                            <p
                              className="font-[600] text-sm dark:text-white truncate"
                              title={data?.client?.name}
                            >
                              {data?.client?.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div
                            className={
                              data.performance?.score > 50
                                ? 'text-[#3CCB7F] text-sm'
                                : 'text-[#FF0000] text-sm'
                            }
                          >
                            {formatNumber(data.performance?.score)}%
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                        <div>No data</div>
                      </div>
                    )}
                  </Col>
                  <Col numColSpan={2}  className='flex justify-center'>
                    <button
                      className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      Show More
                    </button>
                  </Col>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid numItems={2} className="gap-8">
                  <Col>
                    <div className="font-[700] text-[18px] dark:text-white my-4">
                      Top Performance
                    </div>
                    {ecommerceTopData && ecommerceTopData.length > 0 ? (
                      ecommerceTopData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[30vw] py-2">
                            <p
                              className="font-[600] text-sm dark:text-white truncate"
                              title={data?.client?.name}
                            >
                              {data?.client?.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div
                            className={
                              data.performance?.score > 50
                                ? 'text-[#3CCB7F] text-sm'
                                : 'text-[#FF0000] text-sm'
                            }
                          >
                            {formatNumber(data.performance?.score)}%
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                        <div>No data</div>
                      </div>
                    )}
                  </Col>
                  <Col>
                    <div className="font-[700] text-[18px] dark:text-white my-4">
                      Under Performance
                    </div>
                    {ecommerceUnderPerformanceData &&
                    ecommerceUnderPerformanceData.length > 0 ? (
                      ecommerceUnderPerformanceData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[30vw] py-2">
                            <p
                              className="font-[600] text-sm dark:text-white truncate"
                              title={data?.client?.name}
                            >
                              {data?.client?.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div
                            className={
                              data.performance?.score > 50
                                ? 'text-[#3CCB7F] text-sm'
                                : 'text-[#FF0000] text-sm'
                            }
                          >
                            {formatNumber(data.performance?.score)}%
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full flex justify-center items-center text-gray-500 h-[300px]">
                        <div>No data</div>
                      </div>
                    )}
                  </Col>
                  <Col numColSpan={2}  className='flex justify-center'>
                    <button
                      className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      Show More
                    </button>
                  </Col>
                </Grid>
              </TabPanel>
            </TabPanels>
          )}
        </TabGroup>
      </div>
    </div>
  );
};

export default TopAndUnderServicePerformance;
