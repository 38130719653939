import React, { useState, useEffect, useMemo } from 'react';
import BarCharts from '../../components/Insights/BarChart/positionOpportunity';
import PieChart from '../../components/Insights/PieChart/positionOpportunity';
import 'react-datepicker/dist/react-datepicker.css';
import useToolStore from '../../store/useToolStore';
import useAuthStore from '../../store/useAuthStore';
import useUserStore from '../../store/useUserStore';
import ReactSelect from '../../components/ReactSelect';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface StackedBarChartProps {
  data: {
      name: string;
      '1-3': number;
      '4-10': number;
      '11-20': number;
      '21-50': number;
      '51-100': number;
  }[];
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data }) => {
  return (
      <ResponsiveContainer width="60%" height={300}>
          <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="1-3" stackId="a" fill="#fdc23d" />
              <Bar dataKey="4-10" stackId="a" fill="#016dc9" />
              <Bar dataKey="11-20" stackId="a" fill="#008ff8" />
              <Bar dataKey="21-50" stackId="a" fill="#2cb3ff" />
              <Bar dataKey="51-100" stackId="a" fill="#8fcdff" radius={[10, 10, 0, 0]}/>
          </BarChart>
      </ResponsiveContainer>
  );
};

const ContactFinder = () => {
  const [activeTab, setActiveTab] = useState('potentialLeads');
  const { currentUser } = useAuthStore((state) => state);
  const { user, fetchUser } = useUserStore((state) => state);
  const { fetchContacts, fetchCompetitorData, fetchQuotas, loading } = useToolStore((state) => state);
  const [error, setError] = useState('');
  const [reportData, setReportData] = useState({});
  const [payload, setPayload] = useState({
    role: '',
    hubspot_id: '',
    data: [],
    emailData:{},
    emailLoading: false,
    keyword: '',
    database: '',
    databaseLabel: '',
    balance: '',
    semBalance: '',
    resets: '',
    selectedItem: null,
    emailItem: null,
    showModal: false,
    showEmailModal: false,
    chartData: [],
    fourtoten: null,
    intent1:{},
    intent2:{},
    intent3:{},
  });
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const formatMonthYear = (month, year) => `${year}-${String(month + 1).padStart(2, '0')}`;
  const previousMonthYear = formatMonthYear(currentMonth === 0 ? 11 : currentMonth - 1, currentMonth === 0 ? currentYear - 1 : currentYear);

  const { 
    data, 
    hubspot_id,
    emailData, 
    emailLoading,
    keyword, 
    database, 
    databaseLabel, 
    balance, 
    semBalance, 
    resets, 
    selectedItem, 
    emailItem, 
    showModal, 
    showEmailModal, 
    chartData, 
    fourtoten,
    intent1,
    intent2,
    intent3 } = payload;

  const dbOptions = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Australia', value: 'AU' },
    { label: 'Canada', value: 'CA' },
    { label: 'India', value: 'IN' },
  ];

  const handleRowClick = (item, index) => {
    let merged = {...item, index: index}
    setPayload(prevPayload => ({
      ...prevPayload,
      selectedItem: merged,
      showModal: true
    }));
  };

  const SideModal = ({ item, onClose }) => {
    if (!item) return null;
    item.contacts.sort((a, b) => {
      if (a.position && !b.position) return -1;
      if (!a.position && b.position) return 1;
      return 0;
    })
    return (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '40%'}} className="fixed right-0 top-6 h-full w-100 bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Contact Details</h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold">Page Title</h3>
              <p>{item.title}</p>
            </div>

            <div>
              <h3 className="font-semibold">Position</h3>
              <p>{item.index}</p>
            </div>

            <div>
              <h3 className="font-semibold">Domain</h3>
              <p>{item.displayLink}</p>
            </div>

            <div>
              <h3 className="font-semibold">URL</h3>
              <a href={item.link} target="_blank" className="text-blue-600 hover:underline">{item.link}</a>
            </div>

            <div>
              <h3 className="font-semibold">Contacts ({item.contacts?.length || 0})</h3>
              <div className="space-y-2">
                {item.contacts?.map((contact, index) => (
                  <div key={index} className="p-2 bg-gray-50 dark:bg-gray-700 rounded">
                    <p><strong>Email:</strong> {contact.email}</p>
                    <p><strong>Name:</strong> {contact.firstName ? contact.firstName : 'Unknown'} {contact.lastName ? contact.lastName : ''}</p>
                    <p><strong>Position:</strong> {contact.position ? contact.position : 'Unknown'}</p>
                    <p><strong>Hubspot:</strong> {contact.isInCRM?.isInCRM ? <a target="_blank" href={'https://app.hubspot.com/contacts/1590785/record/0-1/'+contact.isInCRM.details.id} className="px-2 py-1 text-s text-red-500">Yes</a> : <span className="px-2 py-1 text-s text-blue-500 border-blue-500">New</span>}</p>
                    <button
                    className="mt-4 rounded-lg text-white text-sm border border-[#005C9F] bg-[#009f50] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
                    onClick={() => handleFetchEmailReport(item, contact)}>Generate Email</button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const EmailModal = ({ item, onClose }) => {
    if (!item) return null;
    console.log('Inside Email', item)
    console.log('Email Data: ', emailData)
    console.log('current rankings: ', fourtoten)
    const options = (emailData as { competitors: { keywords: { Keyword: string; Position: string; 'Search Volume': string; CPC: string; Competition: string; }[] } }).competitors?.keywords?.map(kw => ({
      value: kw, 
      label: kw.Keyword
    })) || [];
    return emailLoading || !fourtoten ? 
    (
      <>
      <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold">Building Email...</h2>
            </div>
          </div>
        </div>
      </>
      ) : (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div style={{top: '4rem', width: '75%'}} className="fixed right-0 h-full bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold">Email Template</h2>
            </div>
            <div className="flex items-center justify-between">
            <div className="mr-4"><strong>KEYWORDS: </strong></div>
              <div className="flex space-x-4"> {/* Added flex and spacing */}
              
                <div>
                  
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent1) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent1: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 1st Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent2) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent2: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 2nd Intent Keyword"
                  />
                </div>
                <div>
                  <ReactSelect
                    options={options}
                    value={options.find(option => option.value === intent3) || null}
                    handleOnChange={(selectedOption: any) => {
                      setPayload(prevPayload => ({
                        ...prevPayload,
                        intent3: selectedOption.value,
                      }));
                    }}
                    placeholder="Select a 3rd Intent Keyword"
                  />
                </div>
              </div>
              <button 
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 ml-6"
              >
                ✕
              </button>
            </div>
          </div>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold"></h3>
              <p><strong>Subject:</strong> </p>
              <p>You're not being found for <strong>RELEVANT_CLIENT_SERVICE</strong></p>
              <hr className="mt-4 mb-4"/>
              <p className="mb-4">Hey {(emailData as { contact?: { firstName: string } }).contact?.firstName || 'there'},</p>
              <p className="mb-4">I hope you're well and don't mind me reaching out.</p>
              <p className="mb-4">We've worked with <strong>RELEVANT_CLIENTS</strong> and many others.</p>
              <p className="mb-4">Our team are running free online performance audits on the lead up to <strong>UPCOMING_EVENT</strong> and would love to take a look at the opportunities available to <strong>{item.displayLink}</strong> from an SEO/Ads perspective, free of charge. It's essentially a digital health check to ensure all your campaigns are performing optimally.</p>
              <p className="mb-4">As it stands, <strong>{item.displayLink} is ranking for {(emailData as { competitors?: { keywordTotals: Array<{ "Organic Keywords": number }> } }).competitors?.keywordTotals?.[0]?.["Organic Keywords"] || 0} keywords</strong> which suggests a lot of room for growth and improvement in terms of online visibility. Of these keywords, <strong>{fourtoten['4-10']} are in positions 4-10 on Google which is within striking distance of where you want them to be.</strong> </p>
              <ul className="mb-4">
                <li key={1}>
                  {Object.keys(intent1).length > 0 ? (
                    <>
                      <strong>&quot;{(intent1 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent1 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent1 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 1 selection...</strong>
                  )}
                </li>
                <li key={2}>
                  {Object.keys(intent2).length > 0 ? (
                    <>
                      <strong>&quot;{(intent2 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent2 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent2 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 2 selection...</strong>
                  )}
                </li>
                <li key={3}>
                  {Object.keys(intent3).length > 0 ? (
                    <>
                      <strong>&quot;{(intent3 as { Keyword: string; 'Search Volume': string; Position: string }).Keyword}&quot;</strong> is searched <strong>{(intent3 as { 'Search Volume': string })['Search Volume']}</strong> times per month and you're on <strong>page {Math.ceil(Number((intent3 as { Position: string }).Position) / 10)}</strong> of Google
                    </>
                  ) : (
                    <strong style={{ color: 'red' }}>Waiting for keyword 3 selection...</strong>
                  )}
                </li>
              </ul>
              <p className="mb-4">There is a lot of opportunity to direct more traffic and sales to your site. Plus, there are hundreds of additional high performing keywords which you're not yet ranking for.</p>
              <p className="mb-4"><strong>SCREENSHOT_GRAPH_BELOW</strong></p>
              <p className="mb-4">If you're working with an agency at the moment or managing your marketing efforts inhouse, this will analyse how your campaigns are performing and we'll offer suggestions for improvement going forward.</p>
              <p className="mb-4"><strong>RELEVANT_CASE_STUDY</strong></p>
              <p className="mb-4">Keen to connect if you have some time later today, otherwise this / next week? Let me know if you have any questions.</p>
              <p className="mb-12"> Book a time for a quick chat <strong style={{color:'blue'}}>MEETING_LINK_HERE</strong>.</p>  
              <div className="space-y-4 mt-4 mb-4">
                <StackedBarChart data={payload.chartData} />
              </div>      
              <p className="mb-6">&nbsp;</p> 
              <p>&nbsp;</p>   
            </div>
          </div>
          
        </div>
      </>
    );
  };

  const handleFetchEmailReport = (item, contact) => {
    setError('');
    setPayload(prevPayload => ({
        ...prevPayload,
        emailItem: item,
        showEmailModal: true,
        emailLoading: true // Set loading to true when fetching data
    }));

    fetchCompetitorData({ domain: item.displayLink, database: database })
        .then(competitors => {
            if (competitors.success === false) {
                setError(competitors.message);
                setPayload(prevPayload => ({
                    ...prevPayload,
                    emailLoading: false // Set loading to false on error
                }));
                return; // Exit early on error
            }

            const aggregatedData: { [key: string]: { [key: string]: number } } = {};

            competitors.data.keywordHistory.forEach(entry => {
                const { year, month, Position } = entry;
                const monthYear = `${year}-${month}`;

                let category;
                if (Position >= 1 && Position <= 3) {
                    category = '1-3';
                } else if (Position >= 4 && Position <= 10) {
                    category = '4-10';
                } else if (Position >= 11 && Position <= 20) {
                    category = '11-20';
                } else if (Position >= 21 && Position <= 50) {
                    category = '21-50';
                } else if (Position >= 51 && Position <= 100) {
                    category = '51-100';
                } else {
                    return; // Skip if position is out of range
                }

                if (!aggregatedData[monthYear]) {
                    aggregatedData[monthYear] = {
                        '1-3': 0,
                        '4-10': 0,
                        '11-20': 0,
                        '21-50': 0,
                        '51-100': 0
                    };
                }

                aggregatedData[monthYear][category] += 1;
            });

            const chartFormatter = Object.entries(aggregatedData).map(([monthYear, categories]) => ({
                name: monthYear,
                '1-3': categories['1-3'] || 0,
                '4-10': categories['4-10'] || 0,
                '11-20': categories['11-20'] || 0,
                '21-50': categories['21-50'] || 0,
                '51-100': categories['51-100'] || 0
            }));

            console.log('Aggregated Data:', aggregatedData); // Debugging information
            console.log('Chart Formatter:', chartFormatter); // Debugging information
            console.log('Previous Month Year:', previousMonthYear); // Log the value of previousMonthYear

            const foundFourtoten = chartFormatter.find(item => item.name === previousMonthYear);
            console.log('Found Fourtoten:', foundFourtoten); // Debugging information

            setPayload(prevPayload => ({
                ...prevPayload,
                emailData: { contact: contact, competitors: competitors.data },
                fourtoten: foundFourtoten,
                chartData: chartFormatter.reverse(),
                emailLoading: false // Set loading to false after data is received
            }));
        })
        .catch(err => {
            console.error('Error fetching competitor data:', err); // Log the error
            setError('An error occurred while fetching data.');
            setPayload(prevPayload => ({
                ...prevPayload,
                emailLoading: false // Set loading to false on error
            }));
        });
};

  const handleFetchingPositions = () => {
    if(keyword == ''){
      setError('keyword cannot be empty');
      return;
    }
    if(database == ''){
      setError('Please select a region to analyze');
      return;
    }

    setError('');
    setPayload(prevPayload => ({
      ...prevPayload,
      emailLoading:true
    }));
    
    fetchContacts(payload)
    .then(data => {
      if(data.success === false){
        setError(data.message);
      }else{
        var limited = data.data.items.slice(30)
        setPayload(prevPayload => ({
          ...prevPayload,
          data: limited,
          balance: data.data.balance.snov.data.balance,
          semBalance: data.data.balance.semRush,
          resets: data.data.balance.snov.data.limit_resets_in
        }));
        return data.data.items
      }
    })
  }

  const onInput = (e) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      keyword: e.target.value,
    }));
  }

  useEffect(() => {
    fetchUser(currentUser.id)
    .then(data => {
      setPayload(prevPayload => ({
        ...prevPayload,
        hubspot_id: user.hubspot_id
      }));
    })
    fetchQuotas()
      .then((response) => {
        setPayload(prevPayload => ({
          ...prevPayload,
          balance: response.data.snov.data.balance,
          semBalance: response.data.semRush,
          resets: response.data.snov.data.limit_resets_in,
          loading: false
        }));
      })
      .catch(error => {
        console.error('Error fetching quotas:', error);
        setPayload(prevPayload => ({
          ...prevPayload,
          loading: false,
          error: 'Failed to load quotas'
        }));
      });
  }, []);

  return (
    <div className="p-4 kpis">
      <h1 className="lg:text-[18px] hidden md:block font-bold font-inter text-[#001C44] dark:text-white whitespace-nowrap mb-2">
        Contact Finder
        <div className="text-xs text-gray-500"><strong>SNOV:</strong> Credits: <strong>{balance}</strong> | Resets in {resets} day(s) | <em>Approx 96 Credits per search</em></div>
        <div className="text-xs text-gray-500"><strong>SERush:</strong> Credits: <strong>{semBalance}</strong> | <em>Approx 2000 Credits per email generation</em></div>
        <div className="text-xs text-gray-500"><strong>GOOGLE:</strong> 10 Free Searches per Day | $5 per 10 Searches Thereafter</div>
      </h1>
      {!loading ? 
      <div className="flex items-center flex-wrap gap-2 mb-4">
        <input
          value={keyword}
          name={'keyword'}
          onChange={(e) => onInput(e)}
          placeholder="Enter keyword"
          width="w-[220px] sm:w-[300px]"
          className="block p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg w-[220px] sm:w-[300px] bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <div>
          <ReactSelect
            options={dbOptions}
            value={database}
            placeholder={databaseLabel ? databaseLabel : 'Select Region'}
            handleOnChange={(selectedOption: any) =>
              setPayload(prevPayload => ({
                ...prevPayload,
                database: selectedOption.value,
                databaseLabel: selectedOption.label,
              }))
            }
          />
        </div>
        <button
          className="rounded-lg text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          onClick={() => handleFetchingPositions()}
        >
          Fetch
        </button>
        <br />
      </div>
      :
      <>
      <div className="flex items-center p-4">
        <div className="flex space-x-1">
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce"></div>
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce [animation-delay:-.3s]"></div>
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce [animation-delay:-.5s]"></div>
        </div> 
        <div className="ml-4 text-sm text-gray-500">Collating Contacts and Results...</div>
      </div>
      <div className="ml-4 text-sm text-gray-500"><small><em>This process can take a couple of minutes due to the rate limits imposed by Hubspot and Snov.io APIs</em></small></div>
      </>
      }
      {error ? <div className="mb-2 text-red-500">{error}</div> : null}
      {!loading && data.length ? 
      <>
      <div className="p-4">
        <div className="mb-4">
          <button
            className={`mr-2 p-2 ${activeTab === 'potentialLeads' ? 'rounded-lg bg-blue-500 text-white' : 'rounded-lg bg-gray-200'}`}
            onClick={() => setActiveTab('potentialLeads')}
          >
            Potential Leads
          </button>
          <button
            className={`p-2 ${activeTab === 'competitors' ? 'rounded-lg bg-blue-500 text-white' : 'rounded-lg bg-gray-200'}`}
            onClick={() => setActiveTab('competitors')}
          >
            Top 30
          </button>

        </div>
      </div>
      {activeTab === 'competitors' ? (
        <>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
          <thead>
            <tr>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                SERP Position
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Page Title
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Domain
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Contacts Found
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Hubspot Found
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Full URL
              </th>
            </tr>
          </thead>
            <tbody>
              {data.slice(0, Math.min(30, data.length)).map((item, index) => {
                return (
                  <tr onClick={() => handleRowClick(item, (index + 1))} key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
                    <td className='z-10  p-2 min-w-fit'>{index + 1}</td>
                    <td className='z-10  p-2 min-w-fit'>{item.title}</td>
                    <td className='z-10  p-2 min-w-fit'>{item.displayLink}</td>
                    <td className='z-10  p-2 min-w-fit'>{item.contacts.length}</td>
                    <td className='z-10  p-2 min-w-fit'>{
                      item.contacts?.reduce((count, contact) => {
                        return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                      }, 0)
                    }
                    </td>
                    <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={item.link} target="_blank">{item.link}</a></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
        ) : (
        <>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
          <thead>
            <tr>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                SERP Position
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Page Title
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Domain
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Contacts Found
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Hubspot Found
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Full URL
              </th>
              <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.slice(30).map((item, index) => {
              return (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
                <td className='z-10  p-2 min-w-fit'>{index + 31}</td>
                <td className='z-10  p-2 min-w-fit'>{item.title}</td>
                <td className='z-10  p-2 min-w-fit'>{item.displayLink}</td>
                <td className='z-10  p-2 min-w-fit'>{item.contacts.length}</td>
                <td className='z-10  p-2 min-w-fit'>{
                  item.contacts?.reduce((count, contact) => {
                    return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                  }, 0)
                }
                </td>
                <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={item.link} target="_blank">{item.link}</a></td>
                <td className='z-10  p-2 min-w-fit'>
                  <button 
                    className="rounded-lg mb-2 text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-2 py-1 dark:text-[#E6EFF5]"
                    onClick={() => handleRowClick(item, (index + 31))}>View Contacts</button> 
                </td>
              </tr>
            )})}
          </tbody>
        </table>
        </>
      )}
      </>
      : ''}
      {selectedItem ? 
        <SideModal item={selectedItem} onClose={() => setPayload(prevPayload => ({
          ...prevPayload,
          selectedItem: null,
          showModal: false,
        }))} /> 
      : null}
      {emailItem ? 
        <EmailModal item={emailItem} onClose={() => setPayload(prevPayload => ({
          ...prevPayload,
          emailItem: null,
          showEmailModal: false,
          intent1: {},
          intent2: {},
          intent3: {}, 
        }))} /> 
      : null}
    </div>
  );
};

export default ContactFinder;


