import { AreaChart } from '@tremor/react';
import { useCallback } from 'react';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import { useThemeStore } from '../../../store/useThemeStore';
import { convertKey } from '../constants';

interface IProps {
  data?: any;
  category?: string[];
  displayConfig?: any;
  isLoading?: boolean;
  hasDollar?: boolean;
  hasPercent?: boolean;
  error?: boolean;
  propertyId?: string;
  viewType?: string;
}

const chartdata = [
  {
    date: "Jan 23",
    SolarPanels: 2890,
  },
  {
    date: "Feb 23",
    SolarPanels: 2756,
  },
  {
    date: "Mar 23",
    SolarPanels: 3322,
  },
  {
    date: "Apr 23",
    SolarPanels: 3470,
  },
  {
    date: "May 23",
    SolarPanels: 3475,
  },
  {
    date: "Jun 23",
    SolarPanels: 3129,
  },
  {
    date: "Jul 23",
    SolarPanels: 3490,
  },
  {
    date: "Aug 23",
    SolarPanels: 2903,
  },
  {
    date: "Sep 23",
    SolarPanels: 2643,
  },
  {
    date: "Oct 23",
    SolarPanels: 2837,
  },
  {
    date: "Nov 23",
    SolarPanels: 2954,
  },
  {
    date: "Dec 23",
    SolarPanels: 3239,
  },
];

const AreaChartDisplay = ({
  data,
  category,
  displayConfig,
  isLoading,
  hasDollar,
  hasPercent,
  error,
  propertyId,
  viewType
}: IProps) => {

  const { mode } = useThemeStore();

  const dataFormatter = useCallback(
    (number: number | string, hasDollar?: boolean, hasPercent?: boolean) => {
      return hasDollar
        ? formatToCurrency(number)
        : formatNumber(number, hasPercent);
    },
    []
  );

  const isLightTheme = displayConfig.themeStyle === 'light';

  return (
    <div>
      <AreaChart
        // valueFormatter={(value) => dataFormatter(value, hasDollar, hasPercent)}
        data={["preview","edit"].includes(viewType) ? convertKey(chartdata,category) : data}
        index="date"
        categories={category}
        showYAxis={false}
        showLegend={false}
        showAnimation={false}
        showGradient={true}
        startEndOnly
        colors={[displayConfig?.themeColor]}
        className={
          isLightTheme
            ? '-mb-2 mt-8 h-48 bg-white'
            : '-mb-2 mt-8 h-48 bg-dark-tremor-background'
        }
      />
    </div>
  );
};

export default AreaChartDisplay;
