import { requests } from './ApiService';

export const Performance = {
  getPerformance: (filters) =>
    requests.get(
      `/api/v1/client_performance${toQueryString({
        report_date: filters.report_date,
        page: filters.page,
        limit: filters.limit,
        all: filters.all,
        search: filters.search,
        cycle: filters.cycle,
        role: filters.role,
        client: filters.client,
        service: filters.service,
        integration: filters.integration,
        sort_by: filters.sortBy,
        sort_order: filters.sortOrder,
        status: filters.status,
        clients: filters.clients,
        users: filters.users,
        category_type: filters.campaign_type,
      })}`
    ),
  getSummary: (filters) =>
    requests.get(
      `/api/v1/client_performance_summary${toQueryString({
        report_date: filters.report_date,
        cycle: filters.cycle,
        role: filters.role,
        service: filters.service,
        client: filters.client,
        integration: filters.integration,
        clients: filters.clients,
        users: filters.users,
        exclude: filters.exclude,
      })}`
    ),
  getInsightsOverall: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/overall${toQueryString({
        service: filters.service,
        report_date: filters.report_date,
        limit: filters.limit,
      })}`
    ),
  getInsightsService: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/service${toQueryString({
        clientId: filters.clientId,
      })}`
    ),
  getInsightsStatus: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/status${toQueryString({
        userId: filters.userId,
        service: filters.service,
      })}`
    ),
  getAllStaffStatus: () =>
    requests.get(
      `/api/v1/client_performance/insights/all`
    ),
  getUserStaffStatus: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/staff${toQueryString({
        users: filters.userId,
        service: filters.service,
      })}`
    ),
  getAMUserStaffStatus: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/am${toQueryString({
        user: filters.user,
        service: filters.service,
      })}`
    ),
  getInsightsUserStatus: (filters) =>
    requests.get(
      `/api/v1/client_performance/user${toQueryString({
        userId: filters.userId,
        service: filters.service,
      })}`
    ),  
  getInsightsSummary: (filters) =>
    requests.get(
      `/api/v1/client_performance/insights/summary${toQueryString({
        userId: filters.userId,
      })}`
    ),
  generatePerformance: (filters: any) =>
    requests.post(`/api/v1/client_performance/generate`, {
      service: filters.service,
      clientsIds: filters.clientIds,
      start_date: filters.start_date,
      end_date: filters.end_date,
    }),
  regeneratePerformance: (filters: any) =>
    requests.patch(
      `/api/v1/client_performance/regenerate${toQueryString({
        service: filters.service,
        clientIds: filters.clientIds,
        start_date: filters.start_date,
      })}`
    ),

  getPerformanceComment: (id: string, filters) => {
    return requests.get(
      `/api/v1/client_performance/${id}/comments${toQueryString({
        page: filters.page,
        limit: filters.limit,
        sort: filters.sort,
      })}`
    );
  },

  createComment: (id: string, data: any) => {
    return requests.post(`/api/v1/client_performance/${id}/comments`, data);
  },
  editComment: (id: string, commentId: string, data: any) => {
    requests.patch(
      `/api/v1/client_performance/${id}/comments/${commentId}`,
      data
    );
  },
  deleteComment: (id: string, commentId: string) => {
    requests.delete(`/api/v1/client_performance/${id}/comments/${commentId}`);
  },
};

// performance unique to format users and clients
const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        params[key] !== 'all' &&
        params[key] !== '' 

    )
    .map((key, index) => {
      if (
        key === 'clients' ||
        key === 'users' ||
        key === 'clientIds'
        // ||
        // key === 'clientPerformanceIds'
      ) {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(
              (value) =>
                `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(value)}`
            )
            .join('&');
        }
        return `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(params[key])}`;
      }
      return `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(params[key])}`;
    })
    .join('');
