import styled from 'styled-components';
import { colors, device } from '../../../utils/settings';
import weeklyReport from '../../../assets/images/weeklyReport.png';
import { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  enable: boolean;
  checked: boolean;
  onClick: () => void;
};

export const CenterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  padding: 20,
  right: '30%',
  width: '100%',
  height: 'fit-content',
  background: '#EAEAEA',
  borderRadius: 5,
  paddingTop: 50,
  boxShadow: ' 0 4px 8px 0 rgba(0,0,0,0.2)',
  input: {
    marginBottom: 20,
    width: 250,
    height: 30,
    outline: 'none',
    paddingLeft: 10,
  },

  [device.sm]: {
    maxWidth: 700,
  },
  '.close-btn': {
    opacity: 0.3,
    position: 'absolute',
    top: 5,
    right: 10,
    cursor: 'pointer',
  },
  '.clients-count': {
    opacity: 0.3,
    position: 'absolute',
    top: 40,
    right: 70,
    cursor: 'pointer',
  },
  '.checkbox': {
    width: 20,
    height: 15,
    margin: 0,
    cursor: 'pointer',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  '.note': {
    fontSize: 12,
    marginLeft: 5,
    fontWeight: '900',
  },
  '.checkbox-wrapper': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
});

export const Text = styled.span({
  fontSize: 25,
  fontWeight: 600,
  opacity: 0.6,
  marginTop: 30,
  marginBottom: 10,
});

export const ManagedReports = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: 600,
  minHeight: 100,
  maxHeight: 450,
  overflowY: 'scroll',
  borderRadius: 5,
  background: colors.white,
  margin: '10px 0',
  padding: '5px 0',
  span: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '5px',
    background: '#EAEAEA 0% 0% no-repeat padding-box',
    height: 20,
    borderRadius: 10,
    cursor: 'ponter',
    opacity: 0.6,
    transition: '.3s',
    margin: 5,
    ':hover': {
      opacity: 0.9,
    },
  },
  p: {
    color: colors.black,
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    cursor: 'pointer',
  },
  label: {
    marginBottom: 5,
  },
});

export const BtnWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: 330,
  marginBottom: 20,
});

export const CancelBtn = styled.button({
  width: 150,
  height: 40,
  background: '#ccc',
  color: colors.white,
  border: 'none',
  borderRadius: 5,
  fontSize: 16,
  cursor: 'pointer',
  boxShadow: ' 0 4px 8px 0 rgba(0,0,0,0.2)',
});

export const DeleteBtn = styled.button<ButtonProps>(
  ({ enable, checked, onClick }) => ({
    width: 150,
    height: 40,
    background: enable ? '#39a342' : '#cf0101',
    opacity: checked ? 0.8 : 0.2,
    color: colors.white,
    border: 'none',
    borderRadius: 5,
    fontSize: 16,
    cursor: checked ? 'pointer' : 'not-allowed',
    boxShadow: ' 0 4px 8px 0 rgba(0,0,0,0.2)',
  })
);

export const ConfirmRejction = styled.textarea(() => ({
  width: '450px !important',
  minHeight: 40,
  marginLeft: 5,
  borderRadius: 3,
  marginBottom: 20,
  background: '#fff',
  border: '1px solid #ddd',
  verticalAlign: 'top',
  padding: 10,
  textAlign: undefined, // or 'left', 'right', 'center', 'justify'
}));

export const DateWrapper = styled.div({
  '.react-datepicker-wrapper': {
    marginBottom: 10,
  },
  '.react-datepicker__input-container input ': {
    height: '30px !important',
    margin: 0,
    padding: '0 20px',
    textAlign: 'center',
    border: '1px solid #ccc',
  },
});

export const Wrapper = styled.div({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 3,
  backdropFilter: 'blur(3px)',
});

export const PreviewHeader = styled.div({
  display: 'flex',
  alignItems: 'start',
  width: '100%',
  minHeight: 350,
  // webkitBoxShadow: '0 6px 18px 0 rgba(0,0,0,.06)',
  // mozBoxShadow: '0 6px 18px 0 rgba(0,0,0,.06)',
  // boxShadow: '0 6px 18px 0 rgba(0,0,0,.06)',
  backgroundImage: `url(${weeklyReport})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

export const ManagedRecipients = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  maxHeight: 450,
  overflowY: 'scroll',
  minHeight: 100,
  background: colors.white,
  padding: '10px 10px 20px 0',
  span: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '5px',
    background: '#EAEAEA 0% 0% no-repeat padding-box',
    height: 30,
    borderRadius: 10,
    cursor: 'ponter',
    opacity: 0.6,
    transition: '.3s',
    margin: 5,
    ':hover': {
      opacity: 0.9,
    },
  },
  p: {
    color: colors.black,
  },
  label: {
    marginBottom: 5,
  },
});

export const Input = styled.input({
  width: '100%',
  height: 45,
  border: 'none',
  borderBottom: '1px solid #ccc',
  lineHeight: 1.2,
  fontSize: 16,
  paddingLeft: 10,
  background: '#FFFFFF',
  color: colors.black,

  ':focus': {
    outline: 'none',
  },
  ':hover': {
    outline: 'none',
  },
  '::placeholder': {
    fontSize: 15,
    color: colors.black,
    opacity: 0.4,
  },
});
