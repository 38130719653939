import { toQueryString } from '../utils/common';
import { requests } from './ApiService';

const analyticsUrl = 'https://staging-analytics.gurulytics.app';
// const analyticsUrl = "http://localhost:3000"

export const Dashboard = {
  getDashboard: (id: string) =>
    requests.get(`/analytics/v1/dashboard/?dashboard_page=${id}`),

  createDashboardItem: (data: any) =>
    requests.post('/analytics/v1/dashboard', data,),

  createDashboardTemplate: (data: any ) =>
    requests.post(`/v1/dashboard/templates`,data, analyticsUrl),

  getDashboardTemplates: () =>
    requests.get(`/analytics/v1/dashboard/templates`),

  integrateDashboardToClient: (id: string, data: any) =>
    requests.put(`/analytics/v1/integrations/client/${id}`,data),

  updateDashboardItem: (id: string, data: any) =>
    requests.put(`/analytics/v1/dashboard/${id}`, data),

  getDashboardTemplate: (id: string) =>
    requests.get(`/analytics/v1/dashboard/templates/client/${id}`),

  getDashboardGraph: (id: string, data: any) =>
    requests.get(
      `/analytics/v1/dashboard/sections/${id}/charts${toQueryString({
        client_id: data.clientId,
        startDate: data.startDate,
        endDate: data.endDate
      })}`
    ),

  getDashboardSection: (id: string) =>
    requests.get(`/analytics/v1/dashboard/sections?dashboard_template_id=${id}`),

  updateDashboardSection: (id: string, data: string) =>
    requests.put(`/v1/dashboard/sections/${id}`,data, analyticsUrl),

  getDashboardPages: (id: string) =>
    requests.get(`/analytics/v1/dashboard/page?client=${id}`),

  createDashboardSection: (data: any) =>
    requests.post('/v1/dashboard/sections', data, '', analyticsUrl),

  updateDashboardPage: (id: string, data: any) =>
    requests.put(`/analytics/v1/dashboard/sections/${id}`, data),
  
  updateDashboardContent: (id: string, data: any) =>
    requests.put(`/v1/dashboard/contents/${id}`, data, analyticsUrl),

  deleteContent: (id: string) => 
    requests.delete(`/analytics/v1/dashboard/contents/${id}`),
  
  deleteSection: (id: string) => 
    requests.delete(`/analytics/v1/dashboard/sections/${id}`),
};
