import { Card, Grid, Col } from '@tremor/react';
import PerformanceInsightsStatus from '../../components/PerformanceInsights/Status';
import PerformanceInsightsPerformance from '../../components/PerformanceInsights/Status/clientPerformance';
import PerformanceInsightsAM from '../../components/PerformanceInsights/Status/clientPerformanceAm';
import PerformanceInsightsStaff from '../../components/PerformanceInsights/Status/clientPerformanceByStaff';
import PerformanceInsightsServiceChart from '../../components/PerformanceInsights/Service';
import TopAndUnderServicePerformance from '../../components/PerformanceInsights/TopAndUnderPerformance';

const PerformanceInsights = () => {
  return (
    <div className="p-6">
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={1}
        numItemsLg={2}
        className="gap-8 bg-white p-[24px] rounded-lg dark:bg-[#0C111D]"
      >
        <Col>
          <PerformanceInsightsPerformance />
        </Col>
        <Col>
          <PerformanceInsightsServiceChart />
        </Col>
        <Col>
          <PerformanceInsightsStaff/>
        </Col>
        <Col>
          <PerformanceInsightsAM />
        </Col>
        <Col numColSpanLg={2}>
          <TopAndUnderServicePerformance />
        </Col>
      </Grid>
    </div>
  );
};

export default PerformanceInsights;
