import { useMemo } from "react";
import { BadgeDelta } from "@tremor/react";
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";
import Icon from '../../../assets/icons/SvgComponent';


interface IProps {
  data?: any;
  isLoading?: boolean;
}

const SessionConversionTable = ({
  data,
  isLoading
}: IProps) => {

  const sessions = data?.session_desc || []
  const conversions = data?.conversions_desc || []

  const landingPageData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Page',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Page' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Page' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Page' || headerId === 'Page') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[150px] sm:w-[48vw] pl-4 h-fit">
            <div className="truncate max-w-[40vw] overflow-hidden">
              {props.getValue() === '' ? 'N/A' : props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Sessions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Sessions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Sessions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Sessions' || headerId === 'Sessions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'sessions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.session)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_sessions)}
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.conversion)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_conversions)}
              </div>
            </div>
          </div>
        ),
      },
    ];
    const mergedData = sessions.map((sessionItem) => {
      const matchingConversion = conversions.find(
        (conversionItem) => conversionItem.page === sessionItem.page
      );

      return {
        page: sessionItem.page,
        sessions: {
          session: Number(sessionItem.sessions),
          previous_period_sessions: sessionItem.previous_period,
        },
        conversions: {
          conversion: matchingConversion
            ? Number(matchingConversion?.conversions)
            : 0,
          previous_period_conversions: matchingConversion
            ? matchingConversion?.previous_period
            : 0, // Assuming previous_period is a string
        },
      };
    });

    conversions.forEach((conversionItem) => {
      const pageExists = mergedData.some(
        (item) => item.page === conversionItem.page
      );
      if (!pageExists) {
        mergedData.push({
          page: conversionItem.page,
          sessions: { session: 0, previous_period_sessions: 0 },
          conversions: {
            conversion: Number(conversionItem.conversions),
            previous_period_conversions: conversionItem.previous_period,
          },
        });
      }
    });
    
    return {
      tableHeader,
      mergedData,
    };
  }, [sessions, conversions]);


  const renderValue = (value: any) => {
    const isString = typeof value === 'string';
    const valueTrend = isString && (value.includes('+') ? true : value.includes('-') ? false : true);
    return valueTrend ? (
      <BadgeDelta
        deltaType="moderateIncrease"
        isIncreasePositive={true}
        className="p-1 text-[10px] h-[20px]"
      >
        {value}
      </BadgeDelta>
    ) : (
      <BadgeDelta
        deltaType="moderateDecrease"
        isIncreasePositive={true}
        className="p-1 text-[10px] h-[20px]"
      >
        {value === 0 ? '0' : value}
      </BadgeDelta>
    );
  };
  
  
  return (
    <div className="relative">
       <AnalyticsTable
            data={
              landingPageData?.mergedData ? landingPageData?.mergedData : []
            }
            columns={landingPageData?.tableHeader}
            loading={false}
            totalDocs={
              landingPageData?.mergedData
                ? landingPageData?.mergedData.length
                : 'N/A'
            }
            sortByColumnId="Sessions"
            pagination={true}
            // error={ga4SeoData?.landingPage?.error}
            // retryMethod={retryFetchGA4Analytics}
          />
    </div>
  )
}

export default SessionConversionTable;